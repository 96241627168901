import { Contract, getContractsEndpoint } from '@cp-cz/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-5/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<Contract[], DefaultBusinessMarketApiErrorCode>({
    dataName: 'contracts',
    fetchCallback() {
        return CpDataApi.get(getContractsEndpoint()).then((response) => response.data);
    },
});

export default reducer;
export const fetchContracts = fetchData;
