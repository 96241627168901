import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { MarketingCardsUi } from '../marketing-card-ui';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';
import { MarketingCardLoadingPlaceholder } from '@cp-shared-5/frontend-ui';
import { Contract } from '@cp-cz/common';

const DashboardMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(MarketingCardsUi);

type DashboardMarketingCardProps = {
    contracts?: Contract[];
    isLoadingContracts: boolean;
    contractsLoadingError: boolean;
};

export const DashboardMarketingCard: React.FC<DashboardMarketingCardProps> = ({
    contracts,
    isLoadingContracts,
    contractsLoadingError,
}) => {
    const {
        cmsContent: dashboardMarketingCard,
        isLoading: isLoadingContent,
        loadingError: contentLoadingError,
    } = useDashboardMarketingCard();

    return (
        <DashboardMarketingCardWithHandlers
            contracts={contracts}
            isLoading={isLoadingContracts || isLoadingContent}
            marketingCards={dashboardMarketingCard?.cards}
            hasError={!!contentLoadingError || !!contractsLoadingError}
            loadingPlaceholder={<MarketingCardLoadingPlaceholder />}
        />
    );
};
