import { BronsonPageWrapper, RouterWrapper } from '@cp-shared-5/frontend-storybook-extensions';
import { storiesOf } from '@storybook/react';
import React from 'react';

import { MasterPage } from './MasterPage';

storiesOf('Pages/MasterPage', module)
    .addDecorator(BronsonPageWrapper)
    .addDecorator(RouterWrapper)
    .add('should display', () => <MasterPage />);

export const MasterPageWrapper = (storyFn: () => void): JSX.Element => <MasterPage>{storyFn()}</MasterPage>;
