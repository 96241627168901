import {
    OpenRequestError,
    OpenRequestDevinculationError,
    OpenRequestEndOfTermCalculationError,
    OpenRequestAbroadTravelError,
} from '../types';

export const maxTotalFilesSize = 4 * 1024 * 1024;

export const defaultValidationOpenRequestErrorMessages: OpenRequestError = {
    picklist: {
        required: 'Please select from the list.',
    },
    question: {
        required: 'Please enter required question.',
        min: 'Field must consists of at least 3 characters.',
        max: 'Field must consists of maximum 2000 characters.',
    },
    files: {
        maxFiles: 'Only one file is allowed to be uploaded',
        fileType: 'Only pdf, bmp, gif, jpeg, jpg, png, tif, tiff, docx, doc types are allowed',
        maxTotalSize: `Total size of uploaded file is larger than 4MB`,
    },
};

export const defaultValidationOpenRequestDevinculationErrorMessages: OpenRequestDevinculationError = {
    picklist: {
        required: 'Please select from the list.',
    },
    contractList: {
        required: 'Please select from the list.',
    },
    endOfPledge: {
        required: 'Please, choose one of provided options.',
    },
    comment: {
        min: 'Field must consists of at least 3 characters',
        max: 'Field must consists of maximum 2000 characters.',
    },
};

export const defaultValidationOpenRequestEndOfTermCalculationErrorMessages: OpenRequestEndOfTermCalculationError = {
    contractList: {
        required: 'Please select from the list.',
    },
    reason: {
        required: 'Please, choose one of provided options.',
    },
    otherExplanation: {
        required: 'Field must be fulfilled.',
    },
    comment: {
        min: 'Field must consists of at least 3 characters.',
        max: 'Field must consists of maximum 2000 characters.',
    },
};

export const defaultValidationOpenRequestAbroadTravelErrorMessages: OpenRequestAbroadTravelError = {
    contractList: {
        required: 'Please select from the list.',
    },
};
