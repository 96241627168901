import * as Yup from 'yup';

export type ValidationMessages = {
    fieldNotEmpty: string;
    fieldLength6To7: string;
    fieldLength5To8: string;
    checkboxMandatory: string;
};

const defaultValidationErrorMessages: ValidationMessages = {
    fieldNotEmpty: 'Pole je povinné',
    fieldLength6To7: 'Zadejte správný formát (6-7místné číslo)',
    fieldLength5To8: 'Zadejte správný formát (5-8 znaků)',
    checkboxMandatory: 'Zaškrtnutí souhlasu je povinné',
};

const regexRules = {
    contractId: /^(\d{6,7})$/,
    registrationPlateCode: /^(\w{5,8})$/,
};

export const registrationValidationSchema = (
    errorMessages: ValidationMessages | undefined = defaultValidationErrorMessages,
) =>
    Yup.object().shape({
        contractId: Yup.string()
            .required(errorMessages.fieldNotEmpty)
            .matches(regexRules.contractId, errorMessages.fieldLength6To7),

        registrationPlateCode: Yup.string()
            .required(errorMessages.fieldNotEmpty)
            .matches(regexRules.registrationPlateCode, errorMessages.fieldLength5To8),
        privacyPolicy: Yup.bool().oneOf([true], errorMessages.checkboxMandatory),
    });
