import React from 'react';
import { Layout } from '@vwfs-bronson/bronson-react';
import { FormView, FormViewProps } from '../form-view';

export const OpenRequestUi: React.FC<FormViewProps> = ({ contracts }) => {
    return (
        <Layout>
            <Layout.Item>
                <FormView contracts={contracts} />
            </Layout.Item>
        </Layout>
    );
};
