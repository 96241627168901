import { EndOfTermCalculationReason } from '@cp-cz/common';

export type EndOfTermCalculationResultType = {
    contractNumber: string;
    reason?: EndOfTermCalculationReason;
    otherExplanation: string;
    comment: string;
};

export type AbroadTravelResultType = {
    contractNumber: string;
};

export type RequestFormResultType = {
    optionKey?: string;
    question: string;
    contractNumber: string;
    endOfPledge: string;
    comment?: string;
    files?: [];
    endOfTermCalculation: EndOfTermCalculationResultType;
    abroadTravel: AbroadTravelResultType;
};

export const getInitialValues: () => RequestFormResultType = () => ({
    optionKey: '',
    question: '',
    comment: '',
    contractNumber: '',
    endOfPledge: '',
    files: [],
    endOfTermCalculation: {
        contractNumber: '',
        reason: undefined,
        otherExplanation: '',
        comment: '',
    },
    abroadTravel: {
        contractNumber: '',
    },
});
