import { ContactDetails, contactDetailsValidationSchema, dialCodeToCountryCode, EditStatus } from '@cp-cz/common';
import {
    Notification,
    NotificationStatus,
    preventSubmit,
    UiBlockingSpinner,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
    ValidatedInput,
} from '@cp-shared-5/frontend-ui';
import { Button, ButtonContainer, DataOverview, Fieldset, Form, Layout } from '@vwfs-bronson/bronson-react';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PhoneInput } from './PhoneInput';
import { isEmpty } from 'lodash';

export const testIds = {
    cancel: 'cancel',
    submit: 'submit',
    email: 'email',
    phoneNumber: 'phoneNumber',
};

export type EditContactDetailsProps = {
    initialValues: ContactDetails;
    handleSubmit: (values: ContactDetails) => void;
    onCancel: () => void;
    lastEditStatus: EditStatus;
    isSubmitting: boolean;
};

export const EditView: React.FC<EditContactDetailsProps> = ({
    initialValues,
    handleSubmit,
    onCancel,
    isSubmitting,
}) => {
    const { t } = useTranslation('my-profile');
    const translationPrefix = 'contact-section.edit-view';
    const [areNoNewChanges, setAreNoNewChanges] = useState<boolean>();

    const trackingSection = 'Contact';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, trackingSection);
    const { onTyping } = useAnalyticsFormTracker({
        startTyping: 'onEditProfileContactTypedIn',
    });
    const { onAction: onValidationError } = useAnalyticsActionTracker('onEditProfileContactValidationError');

    const getInitialErrors = (values: { [k: string]: string | undefined }) =>
        ['email', 'phoneNumber'].filter((element) => !values[element] || values[element] === '').join(', ');
    const getErrors = (errors: { [k: string]: string | undefined }) => Object.keys(errors).join(`, `);

    const errorMessages = {
        email: {
            required: t('contact-section.validation.email.required'),
            valid: t('contact-section.validation.email.valid'),
        },
        phoneNumber: {
            required: t('contact-section.validation.phone-number.required'),
            valid: t('contact-section.validation.phone-number.valid'),
        },
    };

    const areNoChangesInForm = (values: ContactDetails): boolean => {
        const { phoneNumber, countryPrefixDigits, email } = initialValues;
        return (
            phoneNumber === values.phoneNumber &&
            email === values.email &&
            countryPrefixDigits === values.countryPrefixDigits
        );
    };

    const onSubmit = (values: ContactDetails): void =>
        areNoChangesInForm(values) ? setAreNoNewChanges(true) : handleSubmit(values);

    const trackValidationErrors = (values: ContactDetails, errors: { [k: string]: string | undefined }): void => {
        const initialErrors = getInitialErrors(values);
        if (!isEmpty(errors)) {
            const errorsList = getErrors(errors);
            onValidationError(errorsList);
        } else if (!!initialErrors) {
            onValidationError(initialErrors);
        }
    };

    return (
        <DataOverview title={t(`${translationPrefix}.title`)}>
            <UiBlockingSpinner isBlocking={isSubmitting}>
                <Formik
                    initialValues={{
                        email: initialValues.email,
                        phoneNumber: initialValues.phoneNumber,
                        countryPrefixDigits: dialCodeToCountryCode(initialValues.countryPrefixDigits ?? ''),
                    }}
                    onSubmit={onSubmit}
                    validationSchema={contactDetailsValidationSchema(errorMessages)}
                >
                    {(formik): JSX.Element => (
                        <Form
                            onSubmit={preventSubmit()}
                            data-testid="editForm"
                            onChange={() => onTyping(formik.errors, formik.touched)}
                        >
                            <Fieldset>
                                <Fieldset.Row>
                                    <Layout>
                                        <Layout.Item>
                                            <ValidatedInput
                                                label={t(`${translationPrefix}.email.label`)}
                                                name="email"
                                                testId={testIds.email}
                                                type="email"
                                                handleChange={(): void => setAreNoNewChanges(false)}
                                            />
                                        </Layout.Item>
                                        <Layout.Item>
                                            <PhoneInput
                                                inputName={'phoneNumber'}
                                                selectName={'countryPrefixDigits'}
                                                testId={testIds.phoneNumber}
                                                label={t(`${translationPrefix}.mobile-phone.label`)}
                                                defaultPrefix={initialValues?.countryPrefixDigits || ''}
                                                handlePrefixChange={() => onTyping(formik.errors, formik.touched)}
                                            />
                                        </Layout.Item>
                                    </Layout>
                                </Fieldset.Row>
                            </Fieldset>
                            <Fieldset>
                                <Fieldset.Row>
                                    <ButtonContainer center>
                                        <Button
                                            secondary
                                            testId={testIds.cancel}
                                            type="button"
                                            onClick={(): void => onCancel()}
                                        >
                                            {t(`${translationPrefix}.cancel-button.label`)}
                                        </Button>
                                        <Button
                                            testId={testIds.submit}
                                            type="submit"
                                            onClick={(): void => {
                                                setAreNoNewChanges(areNoChangesInForm(formik.values));
                                                trackValidationErrors(formik.values, formik.errors);
                                                formik.submitForm();
                                            }}
                                        >
                                            {t(`${translationPrefix}.confirm-button.label`)}
                                        </Button>
                                    </ButtonContainer>
                                </Fieldset.Row>
                            </Fieldset>
                            {areNoNewChanges && (
                                <Notification
                                    status={NotificationStatus.error}
                                    text={t('contact-section.validation.no-field-changes')}
                                />
                            )}
                        </Form>
                    )}
                </Formik>
            </UiBlockingSpinner>
        </DataOverview>
    );
};
