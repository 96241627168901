import { countryCodes } from '@cp-cz/common';
import { FormField } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { ValidatedSelect } from '@cp-shared-5/frontend-ui';

export const CountryCodeSelection: React.FC<{
    name: string;
    testId: string;
    handleChange?: () => void;
}> = ({ name, testId, handleChange }) => {
    return (
        <FormField type="select" id={'country-code-selection'}>
            <ValidatedSelect
                testId={`${testId}prefix`}
                name={name}
                onChange={handleChange}
                selectItems={countryCodes.map(({ dialCode, name, code }) => ({
                    value: code,
                    label: `${name} +${dialCode}`,
                }))}
            />
        </FormField>
    );
};
