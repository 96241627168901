import { ScrollToTopOnNavigation, Spinner } from '@cp-shared-5/frontend-ui';
import {
    amortizationTablePath,
    changeContactAddressPath,
    changeContactDetailsPath,
    changeMainAddressPath,
    consentHookPagePath,
    cookiePolicyPath,
    dashboardPagePath,
    faqPagePath,
    forbiddenPagePath,
    hookPageRedirectionPath,
    invitationPagePath,
    landingPagePath,
    legalNoticePath,
    loginRedirectPagePath,
    logoutPath,
    myDocumentsPagePath,
    myProfilePagePath,
    myRequestsPagePath,
    notAuthorizedPagePath,
    notFoundPagePath,
    privacyPolicyPath,
    registrationPagePath,
    thirdPartyLicensesPagePath,
    errorPagePath,
} from 'components/navigation/paths';
import { ConnectedRouter } from 'connected-react-router';
import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { AuthenticatedRoute, AuthorizedRoute, AuthProviders, AxiosConfiguration } from './auth';
import { history } from './store/rootReducer';
import {
    AmortizationTablePage,
    CookiePolicyPage,
    DashboardPage,
    FaqPage,
    ForbiddenPage,
    InvitationHandlerPage,
    LegalNoticePage,
    MasterPage,
    MyProfilePage,
    NotAuthorizedPage,
    NotFoundPage,
    OpenRequestPage,
    PrivacyPolicyPage,
    RegistrationPage,
    ErrorPage,
} from './pages';
import { MyDocumentsPage } from 'pages/my-documents-page';
import { LandingPage } from './pages/landing-page';
import { LoginRedirect } from './components/login-redirect';
import { ThirdPartyLicensesPage } from 'pages/third-party-licenses-page';
import { HookPageRedirection } from 'components/hook-page-redirection/HookPageRedirection';
import { ConsentPage } from './pages/consent-page';
import { LogoutPage } from 'pages/logout-page/LogoutPage';

const App: React.FC = () => (
    <div className="App">
        <Suspense fallback={<Spinner center />}>
            <AuthProviders>
                <AxiosConfiguration>
                    <ConnectedRouter history={history}>
                        <LastLocationProvider>
                            <ScrollToTopOnNavigation
                                exceptions={[
                                    { from: /\S*/, to: changeContactDetailsPath() },
                                    { from: /\S*/, to: changeMainAddressPath() },
                                    { from: /\S*/, to: changeContactAddressPath() },
                                    { from: changeContactDetailsPath(), to: myProfilePagePath() },
                                    { from: changeMainAddressPath(), to: myProfilePagePath() },
                                    { from: changeContactAddressPath(), to: myProfilePagePath() },
                                ]}
                            />
                            <MasterPage>
                                <Switch>
                                    <Route exact path={landingPagePath()}>
                                        <LandingPage />
                                    </Route>
                                    <Route path={loginRedirectPagePath()}>
                                        <LoginRedirect />
                                    </Route>
                                    <Route path={logoutPath()}>
                                        <LogoutPage />
                                    </Route>
                                    <Route path={faqPagePath()}>
                                        <FaqPage />
                                    </Route>
                                    <Route path={legalNoticePath()}>
                                        <LegalNoticePage />
                                    </Route>
                                    <Route path={cookiePolicyPath()}>
                                        <CookiePolicyPage />
                                    </Route>
                                    <Route path={privacyPolicyPath()}>
                                        <PrivacyPolicyPage />
                                    </Route>
                                    <Route path={thirdPartyLicensesPagePath()}>
                                        <ThirdPartyLicensesPage />
                                    </Route>
                                    <AuthorizedRoute path={dashboardPagePath()}>
                                        <DashboardPage />
                                    </AuthorizedRoute>
                                    <AuthorizedRoute path={myProfilePagePath()}>
                                        <MyProfilePage />
                                    </AuthorizedRoute>
                                    <AuthorizedRoute path={myDocumentsPagePath()}>
                                        <MyDocumentsPage />
                                    </AuthorizedRoute>
                                    <AuthorizedRoute path={amortizationTablePath()}>
                                        <AmortizationTablePage />
                                    </AuthorizedRoute>
                                    <AuthorizedRoute path={myRequestsPagePath()}>
                                        <OpenRequestPage />
                                    </AuthorizedRoute>
                                    <AuthorizedRoute path={hookPageRedirectionPath()}>
                                        <HookPageRedirection />
                                    </AuthorizedRoute>
                                    <AuthenticatedRoute path={consentHookPagePath()}>
                                        <ConsentPage />
                                    </AuthenticatedRoute>
                                    <AuthenticatedRoute path={registrationPagePath()}>
                                        <RegistrationPage />
                                    </AuthenticatedRoute>
                                    <Route path={invitationPagePath()}>
                                        <InvitationHandlerPage />
                                    </Route>
                                    <Route path={errorPagePath()}>
                                        <ErrorPage />
                                    </Route>
                                    <Route path={forbiddenPagePath()}>
                                        <ForbiddenPage />
                                    </Route>
                                    <Route path={notAuthorizedPagePath()}>
                                        <NotAuthorizedPage />
                                    </Route>
                                    <Route path={notFoundPagePath()}>
                                        <NotFoundPage />
                                    </Route>
                                    <Route path="*">
                                        <Redirect to={notFoundPagePath()} />
                                    </Route>
                                </Switch>
                            </MasterPage>
                        </LastLocationProvider>
                    </ConnectedRouter>
                </AxiosConfiguration>
            </AuthProviders>
        </Suspense>
    </div>
);

export default App;
