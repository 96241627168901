import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';
import cookiePolicyReducer from 'components/cookie-policy/CookiePolicySlice';
import genericErrorReducer from 'components/error-pages/generic/GenericErrorPageSlice';
import forbiddenErrorReducer from 'components/error-pages/forbidden/ForbiddenErrorPageSlice';
import notFoundErrorReducer from 'components/error-pages/not-found/NotFoundErrorPageSlice';
import unauthorizedErrorReducer from 'components/error-pages/unauthorized/UnauthorizedErrorPageSlice';
import faqReducer from 'components/faq/FaqSlice';
import logoutReducer from 'components/logout/LogoutSlice';
import iconFooterReducer from 'components/footer/icon-footer/IconFooterSlice';
import legalFooterReducer from 'components/footer/legal-footer/LegalFooterSlice';
import greetingReducer from 'components/greeting/GreetingSlice';
import legalNoticeReducer from 'components/legal-notice/LegalNoticeSlice';
import privacyPolicyReducer from 'components/privacy-policy/PrivacyPolicySlice';
import contractsReducer from 'components/contracts/ContractsSlice';
import paymentsReducer from 'components/amortization-table/PaymentsSlice';
import myProfileReducer from 'components/my-profile/MyProfileSlice';
import myDocumentsReducer from 'components/my-documents/MyDocumentsSlice';
import customerTypeReducer from 'components/customer-type/CustomerTypeSlice';
import dashboardMarketingCardReducer from 'components/dashboard-marketing-card/DashboardMarketingCardSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thirdPartyLicensesReducer from 'components/third-party-licenses/ThirdPartyLicensesSlice';
import ConsentHookpageReducer from 'components/hook-page-redirection/consent-hookpage-component/ConsentHookpageSlice';

export const history = createBrowserHistory();

const appReducer = combineReducers({
    router: connectRouter(history),
    greeting: greetingReducer,
    contracts: contractsReducer,
    myProfile: myProfileReducer,
    myDocuments: myDocumentsReducer,
    customerType: customerTypeReducer,
    payments: paymentsReducer,
    content: combineReducers({
        legalNotice: legalNoticeReducer,
        cookiePolicy: cookiePolicyReducer,
        privacyPolicy: privacyPolicyReducer,
        genericError: genericErrorReducer,
        notFoundError: notFoundErrorReducer,
        forbiddenError: forbiddenErrorReducer,
        unauthorizedError: unauthorizedErrorReducer,
        iconFooter: iconFooterReducer,
        legalFooter: legalFooterReducer,
        faq: faqReducer,
        logout: logoutReducer,
        dashboardMarketingCard: dashboardMarketingCardReducer,
        thirdPartyLicenses: thirdPartyLicensesReducer,
        ConsentHookpage: ConsentHookpageReducer,
    }),
});

export type RootState = ReturnType<typeof appReducer>;

export const resetStore = createAction('store/reset');

const rootReducer = (state: RootState | undefined, action: AnyAction): RootState => {
    if (action.type === resetStore.toString()) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
