import { Base64File } from '@cp-shared-5/apis';
import { CustomerType } from '../customer';

export enum AddressType {
    MAIN = 'MAIN',
    CONTACT = 'CONTACT',
}

export type Addresses = {
    mainAddress?: AddressBase;
    contactAddress?: AddressBase;
};

export type AddressBase = {
    addressType?: AddressType;
    street?: string;
    zipCode?: string;
    city?: string;
    country?: string;
};

export interface AddressChangeRequest {
    customerType: CustomerType;
    addressType: AddressType;
    country: string;
    zipCode: string;
    street: string | undefined;
    streetNumber: string;
    city: string;
    files?: Base64File[];
}
