import React from 'react';
import { LicensePlate as LicensePlateShared } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';

export const LicensePlate: React.FC<{ registrationNumber?: string; size?: string; className?: string }> = ({
    registrationNumber,
    size,
    className,
}) => {
    const { t } = useTranslation();
    if (!registrationNumber) {
        return null;
    }

    return (
        <LicensePlateShared
            countryCode={t('country-code')}
            size={size || 'small'}
            registrationNumber={registrationNumber}
            horizontalStripEu={true}
            euStars={true}
            className={className}
        />
    );
};
