import { CPDate, formatCpDate } from '@cp-shared-5/common-utilities';
import moment from 'moment-timezone';
import 'moment/locale/cs';
import numeral from 'numeral';
import 'numeral/locales/cs';

// Check out https://momentjs.com/timezone/
export const CZ_TIMEZONE = 'Europe/Prague';

moment.tz.setDefault(CZ_TIMEZONE);

// TODO Check the format definitions
const locale = 'cz';
export const numberFormat = '0,0.[0000]';
export const numberFormatRoundedOne = '0,0.0';
const numeralFormat = '0o';
const currencyFormat = '0,0[.]00';
const longDateFormat = 'DD.MM.YYYY';

export function changeLocale(locale: 'en' | 'cz'): void {
    const local = locale === 'cz' ? 'cs' : locale;
    moment.locale(local);
    numeral.locale(local);
}

changeLocale(locale);

export function formatAsCurrency(value?: number, currency = 'Kč'): string {
    return typeof value === 'number' ? `${numeral(value).format(currencyFormat)} ${currency}` : '';
}

export function formatAsDate(date?: CPDate): string {
    return date ? formatCpDate(date).format(longDateFormat) : '';
}

export function formatAsNumber(value?: number, format: string = numberFormat): string {
    return typeof value === 'number' ? numeral(value).format(format) : '';
}

export function formatAsNumeral(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numeralFormat) : '';
}

export function formatAsDistance(value?: number): string {
    return typeof value === 'number' ? numeral(value).format(numberFormat) : '';
}

export function formatAsEmission(value?: number): string {
    return typeof value === 'number' ? `${numeral(value).format(numberFormat)} g/km` : '';
}

export function formatAsIban(iban?: string): string {
    return (
        iban
            ?.replace(/[^\dA-Z]/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim() ?? ''
    );
}

export function formatAsFileSize(bytes: number, decimal: number): string {
    const unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return (!bytes && '0 Bytes') || (bytes / Math.pow(1024, i)).toFixed(decimal) + ' ' + unit[i];
}
