import React from 'react';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { FinancialDetails, ProductTypeLevel3, InstallmentCreditFinancialDetails } from '@cp-cz/common';
import { useTranslationWithFormatting, TranslationFormat } from 'localization/useTranslationWithFormatting';
import { getRemainingContractDurationDifference } from '../utils';

type FinancialDetailsUiProps = {
    financialDetails?: FinancialDetails;
    productTypeLevel3?: ProductTypeLevel3;
};

export const FinancialDetailsUi: React.FC<FinancialDetailsUiProps> = ({ productTypeLevel3, financialDetails }) => {
    const { t, f } = useTranslationWithFormatting('financial-details');

    if (!financialDetails) {
        return null;
    }

    const isInstallmentCredit = (productTypeLevel3?: ProductTypeLevel3): boolean =>
        productTypeLevel3 === ProductTypeLevel3.ICVBWO || productTypeLevel3 === ProductTypeLevel3.ICVBW;

    const { currencyCode, startDate, endDate, generalTermsCode } = financialDetails;
    const { financeValueOfContract, downPayment, balloonAmount } =
        financialDetails as InstallmentCreditFinancialDetails;

    const noValuePlaceholder = '-';
    const currencyCodeTranslation = currencyCode ? t(`translation:currency.${currencyCode}`) : undefined;

    const getContractDurationValue = (): string | undefined => {
        if (!endDate) {
            return;
        }
        const dateDifference: number = getRemainingContractDurationDifference(endDate);
        if (dateDifference < 0) {
            return;
        }
        if (dateDifference === 0) {
            return t('contract-duration-0-months', { duration: 0 });
        }
        if (dateDifference === 1) {
            return t('contract-duration-1-month', { duration: 1 });
        }
        if (dateDifference > 1 && dateDifference < 5)
            return t('contract-duration-2-4-months', { duration: dateDifference });
        if (dateDifference >= 5) return t('contract-duration-5-months', { duration: dateDifference });
    };

    const installmentCreditItemList: DefinitionListItem[] = [
        {
            label: t('finance-value'),
            value: financeValueOfContract
                ? f(financeValueOfContract, TranslationFormat.CURRENCY, currencyCodeTranslation)
                : noValuePlaceholder,
            testId: 'financial-details-finance-value',
        },
        {
            label: t('down-payment'),
            value: downPayment
                ? f(downPayment, TranslationFormat.CURRENCY, currencyCodeTranslation)
                : noValuePlaceholder,
            testId: 'financial-details-down-payment',
        },
        {
            label: t('balloon-amount'),
            value: balloonAmount
                ? f(balloonAmount, TranslationFormat.CURRENCY, currencyCodeTranslation)
                : noValuePlaceholder,
            testId: 'financial-details-balloon-amount',
        },
    ];

    const itemList: DefinitionListItem[] = [
        {
            label: t('start-date'),
            value: startDate ? f(startDate, TranslationFormat.DATE) : noValuePlaceholder,
            testId: 'financial-details-start-date',
        },
        {
            label: t('end-date'),
            value: endDate ? f(endDate, TranslationFormat.DATE) : noValuePlaceholder,
            testId: 'financial-details-end-date',
        },
        {
            label: t('contract-duration'),
            value: getContractDurationValue(),
            testId: 'financial-details-contract-duration',
        },
        {
            label: t('general-terms'),
            value: generalTermsCode ? (
                <a
                    href={`documents/financial-details/${generalTermsCode}.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {`${t('general-terms-version')} ${generalTermsCode}`}
                </a>
            ) : (
                noValuePlaceholder
            ),
            testId: 'financial-details-general-terms',
        },
        ...(isInstallmentCredit(productTypeLevel3) ? installmentCreditItemList : []),
    ].filter(({ value }) => !!value);

    return <DefinitionListHorizontal list={itemList} />;
};
