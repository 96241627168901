import { formatCpDate } from '@cp-shared-5/common-utilities';

export const currentBrand = process.env.REACT_APP_WEBSITE_BRAND || 'vwfs';
export const integrationApiBaseUrl = process.env.REACT_APP_INTEGRATION_API_BASE_URL;
export const integrationApiBaseUrlLocal = process.env.REACT_APP_API_BASE_URL_LOCAL;
export const apiGatewayConsumerName = 'vwfs-du-cpcz';
export const idpHint = process.env.REACT_APP_IDP_HINT;
export const landingPageUrl = process.env.REACT_APP_LANDING_PAGE_URL;
export const isAfterLoginLocalStorageKey = 'isAfterLogin';
export const isAfterContactDetailsHookPageStorageKey = 'isAfterContactDetailsHookPage';
export const isAfterMarketingConsentHookPageStorageKey = 'isAfterMarketingConsentHookPage';
export const isMarketingConsentSubmittedStorageKey = 'isMarketingConsentSubmitted';
export const areContactDetailsChangedStorageKey = 'areContactDetailsChanged';
export const areContactDetailsConfirmedStorageKey = 'areContactDetailsConfirmed';
export const acceptedConsentDateStorageKey = 'acceptedConsentDate';
export const consentValidDate = formatCpDate('2022-09-01').toCpDate();
