import * as Yup from 'yup';
import {
    OpenRequestError,
    OpenRequestDevinculationError,
    OpenRequestEndOfTermCalculationError,
    OpenRequestAbroadTravelError,
} from '../types';
import {
    defaultValidationOpenRequestErrorMessages,
    defaultValidationOpenRequestDevinculationErrorMessages,
    defaultValidationOpenRequestEndOfTermCalculationErrorMessages,
    defaultValidationOpenRequestAbroadTravelErrorMessages,
} from '../messages';
import {
    AbroadTravelRequest,
    DevinculationRequest,
    EndOfTermCalculationReason,
    EndOfTermCalculationRequest,
    Request,
} from '../../apis';

export const validFileFormats = ['pdf', 'jpeg', 'jpg', 'png', 'bmp', 'tif', 'tiff', 'docx', 'doc'];
export const maxFileSize = 4 * 1024 * 1024;

const isSingleFileWeightInLimit = (files: File[]): boolean =>
    !files?.length || files.every((file: File) => maxFileSize > file.size);

const areFilesInCorrectType = (files: File[]): boolean =>
    !files?.length ||
    files.every((file: File) => {
        const ext = file.name.split('.');
        return validFileFormats.includes(ext[ext.length - 1]);
    });

export const fullOpenRequestValidationSchema = (
    errorMessages: OpenRequestError = defaultValidationOpenRequestErrorMessages,
): Yup.SchemaOf<Request> =>
    Yup.object().shape({
        contractNumber: Yup.string().optional(),
        question: Yup.string()
            .required(errorMessages.question.required)
            .min(3, errorMessages.question.min)
            .max(2000, errorMessages.question.max),
        files: Yup.array()
            .optional()
            .max(1, errorMessages.files.maxFiles)
            .test('maxTotalSize', errorMessages.files.maxTotalSize, (files) =>
                isSingleFileWeightInLimit(files as File[]),
            )
            .test('fileType', errorMessages.files.fileType, (files) => areFilesInCorrectType(files as File[])),
    });

export const frontEndOpenRequestValidationSchema = (
    errorMessages: OpenRequestError = defaultValidationOpenRequestErrorMessages,
): Yup.SchemaOf<Request> =>
    fullOpenRequestValidationSchema(errorMessages)
        .omit(['files'])
        .shape({
            files: Yup.array().optional().max(1, errorMessages.files.maxFiles),
        });

export const fullOpenRequestDevinculationValidationSchema = (
    errorMessages: OpenRequestDevinculationError = defaultValidationOpenRequestDevinculationErrorMessages,
): Yup.SchemaOf<DevinculationRequest> =>
    Yup.object().shape({
        contractNumber: Yup.string().required(errorMessages.contractList.required),
        endOfPledge: Yup.string().required(errorMessages.endOfPledge.required),
        comment: Yup.string()
            .optional()
            .test(
                'empty-or-at-least-3-characters-check',
                errorMessages.comment.min,
                (password) => !password || password.length >= 3,
            )
            .max(2000, errorMessages.comment.max),
    });

export const frontEndOpenRequestDevinculationValidationSchema = (
    errorMessages: OpenRequestDevinculationError = defaultValidationOpenRequestDevinculationErrorMessages,
): Yup.SchemaOf<DevinculationRequest> => fullOpenRequestDevinculationValidationSchema(errorMessages);

export const openRequestEndOfTermCalculationValidationSchema = (
    errorMessages: OpenRequestEndOfTermCalculationError = defaultValidationOpenRequestEndOfTermCalculationErrorMessages,
) => {
    return {
        contractNumber: Yup.string().required(errorMessages.contractList.required),
        reason: Yup.string().required(errorMessages.reason.required),
        otherExplanation: Yup.string().when('reason', {
            is: (val: string) => val === 'Jiné' || val === EndOfTermCalculationReason.OTHER,
            then: Yup.string().required(errorMessages.otherExplanation.required),
        }),
        comment: Yup.string()
            .optional()
            .test(
                'empty-or-at-least-3-characters-check',
                errorMessages.comment.min,
                (password) => !password || password.length >= 3,
            )
            .max(2000, errorMessages.comment.max),
    };
};

export const backEndOpenRequestEndOfTermCalculationValidationSchema = (
    errorMessages: OpenRequestEndOfTermCalculationError = defaultValidationOpenRequestEndOfTermCalculationErrorMessages,
): Yup.SchemaOf<EndOfTermCalculationRequest> => {
    return Yup.object().shape(openRequestEndOfTermCalculationValidationSchema(errorMessages));
};

export const frontEndOpenRequestEndOfTermCalculationValidationSchema = (
    errorMessages: OpenRequestEndOfTermCalculationError = defaultValidationOpenRequestEndOfTermCalculationErrorMessages,
): Yup.SchemaOf<{ endOfTermCalculation: EndOfTermCalculationRequest }> => {
    return Yup.object().shape({
        endOfTermCalculation: Yup.object().shape(openRequestEndOfTermCalculationValidationSchema(errorMessages)),
    });
};

export const openRequestAbroadTravelValidationSchema = (
    errorMessages: OpenRequestAbroadTravelError = defaultValidationOpenRequestAbroadTravelErrorMessages,
): Yup.SchemaOf<{ abroadTravel: AbroadTravelRequest }> => {
    return Yup.object().shape({
        abroadTravel: Yup.object().shape({
            contractNumber: Yup.string().required(errorMessages.contractList.required),
        }),
    });
};
