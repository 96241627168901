import React, { useCallback, useEffect, useState } from 'react';
import { useContract } from '../contracts';
import { AmortizationTableUi } from './ui/AmortizationTableUi';
import { AmortizationTableLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { dashboardPagePath } from '../navigation/paths';
import { useHistory } from 'react-router-dom';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { usePayments } from './usePayments';

const AmortizationTableWithHandlers = withLoadingAndNoConnectionHandler(AmortizationTableUi);
export type AmortizationTableProps = {
    encryptedContractNumber: string;
};

export const AmortizationTable: React.FC<AmortizationTableProps> = ({ encryptedContractNumber }) => {
    const [loadingStarted, setLoadingStarted] = useState(false);
    const history = useHistory();

    const {
        data: contract,
        isLoading: isLoadingContracts,
        loadingError: loadingErrorContract,
    } = useContract(encryptedContractNumber, { encryptedContractNumber: true });

    const {
        data: payments,
        isLoading: isLoadingPayments,
        loadingError: loadingErrorPayments,
    } = usePayments(encryptedContractNumber);

    const onLoading = useCallback(() => {
        if (isLoadingContracts || isLoadingPayments) setLoadingStarted(true);
    }, [isLoadingContracts, isLoadingPayments]);

    useEffect(() => {
        onLoading();
    }, [onLoading]);

    const checkContract = useCallback(() => {
        if (
            loadingStarted &&
            !isLoadingContracts &&
            !loadingErrorPayments &&
            !loadingErrorContract &&
            (!contract || !contract.isActive)
        ) {
            history.push(dashboardPagePath());
        }
    }, [isLoadingContracts, loadingErrorContract, loadingErrorPayments, loadingStarted, contract, history]);

    useEffect(() => {
        checkContract();
    }, [checkContract]);

    const isLoading = isLoadingContracts;
    const hasError =
        !!loadingErrorContract ||
        !!loadingErrorPayments ||
        (!payments?.numberOfInstallments && !contract?.details.financial) ||
        !contract?.isActive;

    useAnalyticsPageViewTracker('amortizationTable', !hasError);

    return (
        <AmortizationTableWithHandlers
            contract={contract}
            amortizationDetails={payments}
            financialDetails={contract?.details.financial}
            isLoading={isLoading}
            hasError={!!hasError}
            loadingPlaceholder={<AmortizationTableLoadingPlaceholder tileElements={3} />}
        />
    );
};
