import { AuthenticationHookResult } from '@cp-shared-5/frontend-ui';
import CryptoJS from 'crypto-js';

export const getHashedUserId = (auth: AuthenticationHookResult): string => {
    if (!auth.isAuthenticated) return '';

    const { idpSub: userId } = auth.tokenParsed as { idpSub: string };

    return CryptoJS.MD5(userId).toString();
};
