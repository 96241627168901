import { Insurance, InsuranceCoverage, CoverageCode, CoverageDesc } from '@cp-cz/common';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const PropertyDemageCoverageWithAllValues: InsuranceCoverage = {
    limitHealthAmt: 45,
    limitPropertyAmt: 55,
    coverageAmt: 65,
    deductibleRate: 0.01,
    deductibleAmt: 45000,
    deductibleHomeServiceRate: 0.11,
    deductibleHomeServiceAmt: 35000,
    deductibleOutsideHomeServiceRate: 0.21,
    deductibleOutsideHomeServiceAmt: 25000,
    coverageCode: CoverageCode.POV,
    coverageDesc: CoverageDesc.PROPERTY_DEMAGE,
    coverageWindowVariantCode: 'CELSKL',
    replacementVehicleDays: '5',
    coverageEndDate: formatCpDate('2020-10-10').toCpDate(),
    coverageExpectedEndDate: formatCpDate('2020-10-10').toCpDate(),
};

export const PropertyDemageCoverageWithMissingValues: InsuranceCoverage = {
    limitHealthAmt: 45,
    coverageCode: CoverageCode.POV,
    coverageDesc: CoverageDesc.PROPERTY_DEMAGE,
};

export const CollisionDemageCoverageWithAllValues: InsuranceCoverage = {
    ...PropertyDemageCoverageWithAllValues,
    coverageCode: CoverageCode.HAV,
    coverageDesc: CoverageDesc.COLLISION_DEMAGE,
};

export const CollisionDemageCoverageWithMissingValues: InsuranceCoverage = {
    coverageCode: CoverageCode.HAV,
    coverageDesc: CoverageDesc.COLLISION_DEMAGE,
    deductibleHomeServiceRate: 0.11,
    deductibleHomeServiceAmt: 35000,
};

export const ReplacementVehicleCoverageWithAllValues: InsuranceCoverage = {
    ...PropertyDemageCoverageWithAllValues,
    coverageCode: CoverageCode.NAV,
    coverageDesc: CoverageDesc.REPLACEMENT_VEHICLE,
};

export const ReplacementVehicleCoverageWithMissingValues: InsuranceCoverage = {
    coverageCode: CoverageCode.NAV,
    coverageDesc: CoverageDesc.REPLACEMENT_VEHICLE,
    replacementVehicleDays: '5',
};

export const WindshieldCoverageWithAllValues: InsuranceCoverage = {
    ...PropertyDemageCoverageWithAllValues,
    coverageCode: CoverageCode.SKL,
    coverageDesc: CoverageDesc.WINDSHIELD_COVERAGE,
};

export const WindshieldCoverageWithMissingValues: InsuranceCoverage = {
    coverageCode: CoverageCode.SKL,
    coverageDesc: CoverageDesc.WINDSHIELD_COVERAGE,
    coverageWindowVariantCode: 'CELSKL',
};

export const OtherCoverageWithAllValues: InsuranceCoverage = {
    ...PropertyDemageCoverageWithAllValues,
    coverageCode: 'ZAV',
    coverageDesc: 'Jiné pojištění',
};

export const OtherCoverageWithMissingValues: InsuranceCoverage = {
    coverageCode: 'ZAV',
    coverageDesc: 'Jiné pojištění',
};

export const SupplementaryCoveragesWithAllValues: InsuranceCoverage[] = [
    ReplacementVehicleCoverageWithAllValues,
    WindshieldCoverageWithAllValues,
    OtherCoverageWithAllValues,
];

export const SupplementaryCoveragesWithMissingValues: InsuranceCoverage[] = [
    ReplacementVehicleCoverageWithMissingValues,
    WindshieldCoverageWithMissingValues,
    OtherCoverageWithMissingValues,
];

export const InsuranceWithAllValuesOne: Insurance = {
    contractInsuranceNumber: '2921002292',
    insuranceStartDate: formatCpDate('2020-01-20').toCpDate(),
    insuranceCompanyName: 'Insurance Company',
    coverages: [
        PropertyDemageCoverageWithAllValues,
        CollisionDemageCoverageWithAllValues,
        ReplacementVehicleCoverageWithAllValues,
        WindshieldCoverageWithAllValues,
        OtherCoverageWithAllValues,
    ],
};

export const InsuranceWithAllValuesTwo: Insurance = {
    contractInsuranceNumber: '123456789',
    insuranceStartDate: formatCpDate('2021-02-15').toCpDate(),
    insuranceCompanyName: 'Insurance Company ABC',
    coverages: [CollisionDemageCoverageWithAllValues, CollisionDemageCoverageWithAllValues],
};

export const InsuranceWithMissingValues: Insurance = {
    contractInsuranceNumber: '2921002292',
    coverages: [
        PropertyDemageCoverageWithMissingValues,
        CollisionDemageCoverageWithMissingValues,
        ReplacementVehicleCoverageWithMissingValues,
        WindshieldCoverageWithMissingValues,
        OtherCoverageWithMissingValues,
    ],
};

export const InsuranceDetailsWithAllValues: Insurance[] = [InsuranceWithAllValuesOne, InsuranceWithAllValuesTwo];
export const InsuranceDetailsWithMissingValues: Insurance[] = [InsuranceWithMissingValues];
export const InsuranceDetailsWithAllMissingValues: Insurance[] = [];
