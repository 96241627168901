import React from 'react';
import {
    AmortizationTableHeader as AmortizationTableHeaderShared,
    DefinitionListHorizontal,
} from '@cp-shared-5/frontend-ui';
import { DataOverview, Layout } from '@vwfs-bronson/bronson-react';
import { TranslationFormat, useTranslationWithFormatting } from '../../../localization/useTranslationWithFormatting';
import { AmortizationDetails, FinancialDetails } from '@cp-cz/common';
import { LicensePlate } from '../../license-plate';

export type AmortizationTableHeaderProps = {
    carInformation: string;
    contractCategory: string;
    contractNumber: string;
    countryCode: string;
    registrationNumber?: string;
    financialDetails: FinancialDetails;
    amortizationDetails: AmortizationDetails;
};

export const AmortizationTableHeader: React.FC<AmortizationTableHeaderProps> = ({
    carInformation,
    contractCategory,
    contractNumber,
    registrationNumber,
    financialDetails,
    amortizationDetails,
}) => {
    const { t, f } = useTranslationWithFormatting('amortization-details');

    const { outstandingBalance, currencyCode, startDate, endDate } = financialDetails;
    const { numberOfInstallments, installmentsPastDueCount } = amortizationDetails;
    const currencyCodeTranslation = currencyCode ? t(`translation:currency.${currencyCode}`) : undefined;

    const getOutstandingBalanceFieldName = (): string =>
        outstandingBalance ? (outstandingBalance > 0 ? 'positive' : 'negative') : 'zero';

    const tilesData = [
        {
            title: t('header.box-1.title'),
            icon: 'semantic-benefit-finance',
            data: [
                {
                    label: (
                        <span
                            dangerouslySetInnerHTML={{
                                __html: t(`header.box-1.field-1.${getOutstandingBalanceFieldName()}`),
                            }}
                        />
                    ),
                    value:
                        typeof outstandingBalance === 'number'
                            ? f(Math.abs(outstandingBalance), TranslationFormat.CURRENCY, currencyCodeTranslation)
                            : '-',
                    tooltip: outstandingBalance ? t('header.box-1.field-1.tooltip') : undefined,
                },
            ],
        },
        {
            title: t('header.box-2.title'),
            icon: 'semantic-list',
            data: [
                {
                    label: t('header.box-2.field-1'),
                    value: numberOfInstallments || '-',
                },
                {
                    label: installmentsPastDueCount ? t('header.box-2.field-2') : null,
                    value: installmentsPastDueCount || null,
                },
            ],
        },
        {
            title: t('header.box-3.title'),
            icon: 'semantic-calendar',
            data: [
                {
                    label: t('header.box-3.field-1'),
                    value: startDate ? f(startDate, TranslationFormat.DATE) : '-',
                },
                {
                    label: t('header.box-3.field-2'),
                    value: endDate ? f(endDate, TranslationFormat.DATE) : '-',
                },
            ],
        },
    ];

    const Tiles = (
        <>
            {tilesData.map((tile, index) => (
                <Layout.Item key={index} default="1/3" m="1/1">
                    <DataOverview title={tile.title} icon={tile.icon}>
                        <DefinitionListHorizontal use66To33={true} list={tile.data} />
                    </DataOverview>
                </Layout.Item>
            ))}
        </>
    );

    return (
        <AmortizationTableHeaderShared
            carInformation={carInformation}
            contractCategory={contractCategory}
            thirdLineData={contractNumber}
            contractNumber={''}
            licensePlateComponent={<LicensePlate registrationNumber={registrationNumber} />}
            tiles={Tiles}
        />
    );
};
