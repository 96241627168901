import { DataOverview } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactDetails, EditStatus } from '@cp-cz/common';
import { ContactList } from './ContactList';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';

export type ConsultViewProps = {
    contactDetails?: ContactDetails;
    lastEditStatus: EditStatus;
    startEditing: () => void;
};

export const ConsultView: React.FC<ConsultViewProps> = ({ contactDetails, startEditing, lastEditStatus }) => {
    const { t } = useTranslation('my-profile');

    const status = lastEditStatus === EditStatus.SUCCESS ? 'success' : 'error';
    const isNotificationFlag = !Object.values(contactDetails || {}).every((el) => !!el === false);
    return (
        <DataOverview
            title={t('contact-section.consult-view.title')}
            buttonLabel={t('contact-section.consult-view.edit-button')}
            buttonProps={{ onClick: startEditing, testId: 'editButton' }}
        >
            <>
                {isNotificationFlag && (
                    <Notification
                        status={NotificationStatus.info}
                        text={t('contact-section.consult-view.disclaimer')}
                        className={`u-mb`}
                    />
                )}
                <ContactList contactDetails={contactDetails} />
                {lastEditStatus !== EditStatus.NOT_PERFORMED && (
                    <Notification
                        status={NotificationStatus[status]}
                        headline={t(`contact-section.notification.${status}.title`)}
                        text={t(`contact-section.notification.${status}.description`)}
                    />
                )}
            </>
        </DataOverview>
    );
};
