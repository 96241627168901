import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-5/frontend-storybook-extensions';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-5/common-utilities';
import { getContractsEndpoint } from '@cp-cz/common';
import { ContractsWithAllValues, ContractsWithDifferentNumber } from './ExampleData';
import { MyProfileNaturalMock } from '../my-profile';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: ContractsWithAllValues,
    },
    'Without requested contract': {
        status: 200,
        responseBody: ContractsWithDifferentNumber,
    },
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const ContractsMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'Contracts Response',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getContractsEndpoint(),
};

export const ContractsResponseMockWrapper = (storyFn: () => React.ReactNode): JSX.Element => {
    setupMockResponses(CpDataApi, [ContractsMock, MyProfileNaturalMock]);

    return <div>{storyFn()}</div>;
};
