import React, { useEffect, useState } from 'react';
import { DocumentType } from '@cp-cz/common';
import { useTranslation } from 'react-i18next';
import {
    InfoIcon,
    Notification,
    NotificationStatus,
    Postbox,
    PostboxDocument,
    PostboxFilterProps,
    useAnalyticsActionTracker,
} from '@cp-shared-5/frontend-ui';
import {
    getContractFilterItems,
    getDevinculationDocumentSubject,
    getDocumentTypeFilterItems,
    getTimeFrameFilterItems,
    MyDocument,
    sortDocuments,
    getAbroadTravelDocumentSubject,
} from '../utils';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Modal } from '@vwfs-bronson/bronson-react';
import base64ToBlob from 'b64-to-blob';
import { TFunctionResult } from 'i18next';
import { kebabCase } from 'lodash';
import { CpDataApi } from 'cp-xhr';
import { formatCpDate } from '@cp-shared-5/common-utilities';

export const MyDocumentsUi: React.FC<{ myDocuments?: MyDocument[] }> = ({ myDocuments }) => {
    const { t } = useTranslation('my-documents');
    const [loadingDocuments, setLoadingDocuments] = useState<{ [key: number]: boolean | undefined }>({});
    const [startDownload, setStartDownload] = useState<number | null>(null);
    const [finishDownload, setFinishDownload] = useState<number | null>(null);
    const [sortedDocs] = useState<MyDocument[]>(sortDocuments(myDocuments || []));
    const [downloadError, setDownloadError] = useState(false);
    const { onAction: onPostboxDownload } = useAnalyticsActionTracker('onPostboxDownload');
    const { onAction: onPostboxDownloadError } = useAnalyticsActionTracker('onPostboxDownloadError');

    // UseEffect has to be used to update the loadingDocuments state. Otherwise it is not ensured that the latest loadingDocumets is taken for setLoadingDocuments if several document downloads are started in paralell (due to the asynchronious state update).
    useEffect(() => {
        if (startDownload !== null && !loadingDocuments[startDownload]) {
            setLoadingDocuments({ ...loadingDocuments, [startDownload]: true });
            setStartDownload(null);
        }
        if (finishDownload !== null && loadingDocuments[finishDownload]) {
            setLoadingDocuments({ ...loadingDocuments, [finishDownload]: false });
            setFinishDownload(null);
        }
    }, [startDownload, finishDownload, loadingDocuments]);

    const getNoDocumentsNotification = (): React.ReactNode => {
        return (
            <Notification
                status={NotificationStatus.info}
                testId={'notification-no-documents'}
                headline={t('notification.no-documents.title')}
                text={t('notification.no-documents.text')}
            />
        );
    };

    const getContractFilterItem = (document: MyDocument): React.ReactNode => {
        return (
            <>
                <span>
                    <b>
                        {document.productName} ({document.contractNumber})
                    </b>
                </span>
                <br />
                {(document.brandModelType || document.licensePlate) && (
                    <span>
                        {document.brandModelType} {document.licensePlate ? `(${document.licensePlate})` : ''}
                    </span>
                )}
            </>
        );
    };

    const filters: PostboxFilterProps = {
        documentTypeFilter: {
            label: t('filters.document-type.label'),
            filterItems: getDocumentTypeFilterItems(sortedDocs),
            allLabel: t('filters.document-type.default-value'),
        },
        contractIdentifierFilter: {
            label: t('filters.contract.label'),
            filterItems: getContractFilterItems(sortedDocs, getContractFilterItem),
            allLabel: t('filters.contract.default-value'),
        },
        timeFrameFilter: {
            label: t('filters.time-period.label'),
            filterItems: getTimeFrameFilterItems(t),
            allLabel: t('filters.time-period.default-value'),
        },
    };

    const downloadPdf = (document: MyDocument, index: number, e: Event | undefined) => {
        setStartDownload(index);
        if (e) e.preventDefault();
        if (!document._downloadLink) {
            onPostboxDownloadError(document.categoryType);
            setDownloadError(true);
            return;
        }

        let fileName: string;

        switch (document.documentType) {
            case `${kebabCase(DocumentType.DRIVER_SET)}-retail`:
            case `${kebabCase(DocumentType.DRIVER_SET)}-fleet`:
                fileName = `${document.contractNumber}_${t(`document-name.${document.documentType}`)}_${moment(
                    document.contractStartDate,
                ).format('DD_MM_YYYY')}.pdf`;
                // TODO: use API call as for registration certificate when possible; currently files are too big
                saveAs(document._downloadLink, fileName);
                onPostboxDownload(document.categoryType);
                setFinishDownload(index);
                break;
            case DocumentType.COPY_OF_REGISTRATION_CERTIFICATE:
                CpDataApi.get(document._downloadLink)
                    .then((response) => {
                        const fileName = `${document.contractNumber}_3_${formatCpDate(
                            document.contractStartDate,
                        ).format('DD_MM_YYYY')}.pdf`;
                        const pdfContentType = 'application/pdf';
                        const pdfBlob = base64ToBlob(response.data, pdfContentType);
                        saveAs(pdfBlob, fileName);
                        onPostboxDownload(document.categoryType);
                        setFinishDownload(index);
                    })
                    .catch(() => {
                        setDownloadError(true);
                        onPostboxDownloadError(document.categoryType);
                        setFinishDownload(index);
                    });
                break;
            case DocumentType.INVOICE_MONTHLY:
            case DocumentType.INVOICE_AD_HOC:
                CpDataApi.get(document._downloadLink)
                    .then((response) => {
                        const fileName = `${document.contractNumber}_${document.invoiceId}_${formatCpDate(
                            document.issueDate,
                        ).format('DD_MM_YYYY')}.pdf`;
                        const pdfContentType = 'application/pdf';
                        const pdfBlob = base64ToBlob(response.data, pdfContentType);
                        saveAs(pdfBlob, fileName);
                        onPostboxDownload(document.categoryType);
                        setFinishDownload(index);
                    })
                    .catch(() => {
                        setDownloadError(true);
                        onPostboxDownloadError(document.categoryType);
                        setFinishDownload(index);
                    });
                break;
            case DocumentType.CREDIT_NOTE:
                CpDataApi.get(document._downloadLink)
                    .then((response) => {
                        const fileName = `${document.contractNumber}_DDD_${formatCpDate(document.issueDate).format(
                            'DD_MM_YYYY',
                        )}.pdf`;
                        const pdfContentType = 'application/pdf';
                        const pdfBlob = base64ToBlob(response.data, pdfContentType);
                        saveAs(pdfBlob, fileName);
                        onPostboxDownload(document.categoryType);
                        setFinishDownload(index);
                    })
                    .catch(() => {
                        setDownloadError(true);
                        onPostboxDownloadError(document.categoryType);
                        setFinishDownload(index);
                    });
                break;
            case DocumentType.GENERAL_TERMS:
                fileName = `${document.contractNumber}_${document.categoryType}_${formatCpDate(
                    document.contractStartDate,
                ).format('DD_MM_YYYY')}.pdf`;
                saveAs(document._downloadLink, fileName);
                onPostboxDownload(document.categoryType);
                setFinishDownload(index);
                break;
            case DocumentType.ABROAD_TRAVEL:
                const abroadTravelRequestData = {
                    contractNumber: document.encryptedContractNumber,
                };

                CpDataApi.post(document._downloadLink, abroadTravelRequestData)
                    .then((response) => {
                        const pdfContentType = 'application/pdf';
                        const pdfBlob = base64ToBlob(response.data.base64, pdfContentType);
                        saveAs(pdfBlob, response.data.filename);
                        onPostboxDownload(document.categoryType);
                        setFinishDownload(index);
                    })
                    .catch(() => {
                        setDownloadError(true);
                        onPostboxDownloadError(document.categoryType);
                        setFinishDownload(index);
                    });
                break;
            case DocumentType.DEVINCULATION_DOCUMENT:
                const devinculationRequestData = {
                    endOfPledge: document.devinculationEndOfPledge,
                    contractNumber: document.encryptedContractNumber,
                };
                CpDataApi.post(document._downloadLink, devinculationRequestData)
                    .then((response) => {
                        const pdfContentType = 'application/pdf';
                        const pdfBlob = base64ToBlob(response.data.base64, pdfContentType);
                        saveAs(pdfBlob, response.data.filename);
                        onPostboxDownload(document.categoryType);
                        setFinishDownload(index);
                    })
                    .catch(() => {
                        setDownloadError(true);
                        onPostboxDownloadError(document.categoryType);
                        setFinishDownload(index);
                    });
                break;
            case DocumentType.GREEN_CARD:
            case DocumentType.GREEN_CARD_UPCOMING:
                CpDataApi.post(document._downloadLink, document.greenCardData)
                    .then((response) => {
                        const fileName = `${document.contractNumber} - ${formatCpDate(
                            document.contractStartDate,
                        ).format('DD_MM_YYYY')}.pdf`;
                        const pdfContentType = 'application/pdf';
                        const pdfBlob = base64ToBlob(response.data.base64, pdfContentType);
                        saveAs(pdfBlob, fileName);
                        onPostboxDownload(document.categoryType);
                        setFinishDownload(index);
                    })
                    .catch(() => {
                        setDownloadError(true);
                        onPostboxDownloadError(document.categoryType);
                        setFinishDownload(index);
                    });
        }
    };

    const mapDocumentSubject = (myDocument: MyDocument): TFunctionResult | string | React.ReactNode => {
        switch (myDocument.documentType) {
            case DocumentType.GENERAL_TERMS:
                return t(`document-name.${kebabCase(DocumentType.GENERAL_TERMS)}`, {
                    generalTermsCode: myDocument.generalTermsCode,
                    contractNumber: myDocument.contractNumber,
                });
            case DocumentType.GREEN_CARD:
                const subject = t(`document-name.${kebabCase(DocumentType.GREEN_CARD)}`, {
                    licensePlate: myDocument.greenCardData?.registrationNumber,
                });

                const isTodayAtLeastSixMonthsOrLessBeforeValidTo = formatCpDate()
                    .toMoment()
                    .isSameOrAfter(formatCpDate(myDocument.greenCardData?.dateTo).toMoment().subtract(6, 'months'));

                return isTodayAtLeastSixMonthsOrLessBeforeValidTo ? (
                    <>
                        <span className={'u-mr-xsmall'}>{subject}</span>
                        <InfoIcon text={t('green-card-tooltip')} />
                    </>
                ) : (
                    subject
                );
            case DocumentType.GREEN_CARD_UPCOMING:
                return t(`document-name.${kebabCase(DocumentType.GREEN_CARD_UPCOMING)}`, {
                    licensePlate: myDocument.licensePlate,
                });
            case DocumentType.COPY_OF_REGISTRATION_CERTIFICATE:
                return t(`document-name.${kebabCase(DocumentType.COPY_OF_REGISTRATION_CERTIFICATE)}`, {
                    licensePlate: myDocument.licensePlate,
                });
            case `${kebabCase(DocumentType.DRIVER_SET)}-retail`:
            case `${kebabCase(DocumentType.DRIVER_SET)}-fleet`:
                return t(`document-name.${kebabCase(myDocument.documentType)}`);
            case DocumentType.DEVINCULATION_DOCUMENT:
                return getDevinculationDocumentSubject(myDocument, t);
            case DocumentType.ABROAD_TRAVEL:
                return getAbroadTravelDocumentSubject(myDocument, t);
            default:
                return t(`document-name.${kebabCase(myDocument.documentType)}`);
        }
    };

    const mapDocuments = (documents: MyDocument[]): PostboxDocument[] => {
        return documents.map((myDocument, index) => {
            return {
                documentId: index,
                documentType: myDocument.categoryType || '',
                contractIdentifier: myDocument.contractNumber,
                loading: !!loadingDocuments[index],
                read: true,
                date: moment(myDocument?.issueDate ?? myDocument.contractStartDate),
                subject: mapDocumentSubject(myDocument),
                onClick: (event) => downloadPdf(myDocument, index, event),
            };
        });
    };

    const tableProps = {
        documents: mapDocuments(sortedDocs),
        tableHeaderColumnLabels: {
            date: t('table-header.date'),
            action: t('table-header.download'),
            subject: t('table-header.subject'),
        },
        dateFormatter: (day: string, month: string, year: string) => `${day}.${month}.${year}`,
        noDocumentsSelectedErrorText: t('notification.no-filter-results.text'),
    };
    return (
        <>
            <h3>{t('subtitle')}</h3>
            <p>{t('description')}</p>
            <br />
            {!tableProps.documents.length && getNoDocumentsNotification()}
            {!!tableProps.documents.length && (
                <Postbox
                    filters={filters}
                    table={tableProps}
                    resetButtonText={t('filters.clear-button')}
                    withPaging
                    itemsPerPage={10}
                />
            )}
            <Modal
                shown={Boolean(downloadError)}
                status="error"
                title={t('notification.download-failure.title')}
                onClose={(): void => setDownloadError(false)}
                testId={'notification-download-failure'}
            >
                {t('notification.download-failure.text')}
            </Modal>
        </>
    );
};
