import { Base64File } from '@cp-shared-5/apis';

export type Request = {
    contractNumber?: string;
    question: string;
    files?: Base64File[];
};

export enum EndOfPledgeOptions {
    CUSTOMER_INVOICE = 'CUSTOMER_INVOICE',
    CUSTOMER_BUDGET = 'CUSTOMER_BUDGET',
    AUTHORIZED_SERVICE = 'AUTHORIZED_SERVICE',
    NON_AUTHORIZED_SERVICE = 'NON_AUTHORIZED_SERVICE',
}

export type DevinculationRequest = {
    contractNumber: string;
    endOfPledge: string;
    comment?: string;
};

export enum DevinculationDocumentShortcode {
    IN_FAVOR_OF_CUSTOMER_INVOICE = 'ROF1',
    IN_FAVOR_OF_CUSTOMER_BUDGETING = 'ROF2',
    IN_FAVOR_OF_AUTHORIZED_SERVICE = 'ROF3',
    IN_FAVOR_OF_NOT_AUTHORIZED_SERVICE = 'ROF4',
}

export enum DevinculationDocumentType {
    IN_FAVOR_OF_CUSTOMER_INVOICE = 'VINKUL',
    IN_FAVOR_OF_CUSTOMER_BUDGETING = 'VINKROZ',
    IN_FAVOR_OF_AUTHORIZED_SERVICE = 'VINKUOPR',
    IN_FAVOR_OF_NOT_AUTHORIZED_SERVICE = 'SOUHLOPR',
}

export const VirusScanErrorCode = 'VIRUS_SCAN_ERROR';

export enum EndOfTermCalculationReason {
    PAY_FROM_SAVINGS = 'PAY_FROM_SAVINGS',
    VEHICLE_SELLING_BUYING_NEW_VIA_VWFS = 'VEHICLE_SELLING_BUYING_NEW_VIA_VWFS',
    VEHICLE_SELLING = 'VEHICLE_SELLING',
    VEHICLE_SELLING_BUYING_NEW_FOR_CASH = 'VEHICLE_SELLING_BUYING_NEW_FOR_CASH',
    REFUND_CREDIT = 'REFUND_CREDIT',
    CANCELLING_CREDIT = 'CANCELLING_CREDIT',
    VEHICLE_THEFT = 'VEHICLE_THEFT',
    ONLY_INFORMATION = 'ONLY_INFORMATION',
    OTHER = 'OTHER',
}

export type EndOfTermCalculationRequest = {
    contractNumber: string;
    reason: string;
    otherExplanation?: string;
    comment?: string;
};

export type AbroadTravelRequest = {
    contractNumber: string;
};
