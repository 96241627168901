import {
    AnalyticsProvider,
    hasError,
    isLoading,
    MasterPage as MasterPageShared,
    notInitiated,
    useAuthentication,
} from '@cp-shared-5/frontend-ui';
import { IconFooter } from 'components/footer/icon-footer';
import { LegalFooter } from 'components/footer/legal-footer';
import { NavigationBar } from 'components/navigation/NavigationBar';
import React, { memo } from 'react';
import {
    landingPagePath,
    loginPath,
    loginRedirectPagePath,
    registrationPagePath,
} from '../../components/navigation/paths';
import { currentBrand } from '../../config';
import { useCustomerType } from '../../components/customer-type';
import { useHistory } from 'react-router-dom';
import { useRegistrationFlow } from '../../auth';

const pagesWithoutFooter = [landingPagePath(), loginRedirectPagePath()];

type RegistrationState = 'WAITING' | 'UNREGISTERED' | 'REGISTERED';

export const MasterPage: React.FC = ({ children }) => {
    const { isAuthenticated } = useAuthentication();
    return <MasterPageComponent isAuthenticated={isAuthenticated}>{children}</MasterPageComponent>;
};

const MasterPageComponent: React.FC<{ isAuthenticated: boolean }> = memo(({ isAuthenticated, children }) => {
    const { state: registrationState } = useRegistrationFlow();

    const getRegistrationState = (): RegistrationState => {
        if (notInitiated(registrationState) || isLoading(registrationState) || hasError(registrationState)) {
            return 'WAITING';
        } else if (!registrationState.isAuthorized) {
            return 'UNREGISTERED';
        }
        return 'REGISTERED';
    };
    const isRegistered = getRegistrationState();
    const fetchingAllowed = !!isAuthenticated && isRegistered === 'REGISTERED';

    const { data: customerType } = useCustomerType(fetchingAllowed);

    const history = useHistory();

    const getUserGroup = () => {
        if (isAuthenticated && !!customerType) {
            switch (customerType) {
                case 'NATURAL_PERSON':
                    return 'Private';
                case 'NATURAL_PERSON_WITH_LEGAL_PERSONALITY':
                    return 'Business (small)';
                case 'LEGAL_PERSON':
                    return 'Business (corporate)';
                default:
                    return;
            }
        }
    };

    const currentPathName = history.location.pathname;
    const isFooterHidden = pagesWithoutFooter.includes(currentPathName);

    return (
        <AnalyticsProvider
            market="CZ"
            isAuthenticated={isAuthenticated}
            version="1"
            releaseDate="2021-10-01"
            language={'cs'}
            registrationPath={registrationPagePath()}
            loginPath={loginPath()}
            userGroup={getUserGroup()}
            brand={currentBrand}
        >
            <MasterPageShared
                navigationBar={<NavigationBar />}
                iconFooter={isFooterHidden ? null : <IconFooter />}
                legalFooter={isFooterHidden ? null : <LegalFooter />}
            >
                {children}
            </MasterPageShared>
        </AnalyticsProvider>
    );
});

MasterPageComponent.displayName = 'MasterPageComponent';
