import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { vwfsVerificationPagePath } from '../../navigation/paths';
import { Button, Card, Checkbox, ErrorMessage, Heading, Layout, Paragraph } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';

type RegistrationUiProps = {
    handleBankIdRegistrationClick: () => void;
    clearError: () => void;
    errorMessageKey?: string;
};

export const RegistrationUi: React.FC<RegistrationUiProps> = ({
    handleBankIdRegistrationClick,
    clearError,
    errorMessageKey,
}) => {
    const history = useHistory();
    const { t } = useTranslation('registration');
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const scrollToRef = useRef<null | HTMLDivElement>(null);
    const executeScroll = () => scrollToRef.current?.scrollIntoView();

    return (
        <>
            <Layout.Item className="u-text-center">
                <Heading level="1">{t('title')}</Heading>
            </Layout.Item>
            <Layout.Item className={'u-mt-none'}>
                {!!errorMessageKey && (
                    <Notification
                        testId={`bank-id.error.${errorMessageKey}`}
                        status={NotificationStatus.error}
                        text={t(`bank-id.error.${errorMessageKey}`)}
                    />
                )}
            </Layout.Item>
            <Layout.Item>
                <div
                    ref={scrollToRef}
                    dangerouslySetInnerHTML={{ __html: t('description') }}
                    className="u-text-center"
                />
                <Checkbox
                    name="privacyPolicy"
                    testId={'privacy-policy'}
                    error={checkboxError}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.checked) {
                            setCheckboxError(false);
                        }
                        setPrivacyPolicyAccepted(e.target.checked);
                    }}
                >
                    {t('checkbox.label')}
                    <a href={t('checkbox.link-address')} target="_blank" rel="noopener noreferrer">
                        {t('checkbox.link')}
                    </a>
                    {t('checkbox.label-after-link')}
                </Checkbox>
                {checkboxError && <ErrorMessage>{t('checkbox.validation-error')}</ErrorMessage>}
            </Layout.Item>
            <Layout.Item className={`u-text-center ${checkboxError ? 'u-mt-small' : ''}`}>
                {t('verification-description')}
            </Layout.Item>
            <Layout.Item>
                <Card
                    key={'vwfs'}
                    element="article"
                    footer={true}
                    className={'u-pt-none u-mt-none'}
                    buttons={
                        <Button
                            link={true}
                            icon="semantic-forward"
                            testId={'vwfs-link'}
                            iconReversed
                            small
                            onClick={() => {
                                if (!privacyPolicyAccepted) {
                                    executeScroll();
                                    setCheckboxError(true);
                                    return;
                                }
                                clearError();
                                history.push(vwfsVerificationPagePath());
                            }}
                        >
                            {t('vwfs-module.link')}
                        </Button>
                    }
                >
                    <img
                        alt={'vwfs verification'}
                        src={'/contents/vwfs_kyc_logo.png'}
                        className="u-block-center u-mb u-hide@s-up"
                    />
                    <img
                        alt={'vwfs verification'}
                        src={'/contents/vwfs_kyc_logo.png'}
                        className="u-block-center u-mb c-logo c-logo--has-image u-hide@s"
                    />
                    {t('vwfs-module.text')}
                </Card>
            </Layout.Item>
            <Layout.Item>
                <Paragraph className={'u-text-center u-mb-none'}>{t('or-divider')}</Paragraph>
            </Layout.Item>
            <Layout.Item>
                <Card
                    key={'bankID'}
                    element="article"
                    footer={true}
                    buttons={
                        <Button
                            link={true}
                            testId={'bank-id-link'}
                            icon="semantic-forward"
                            iconReversed
                            small
                            onClick={() => {
                                if (!privacyPolicyAccepted) {
                                    executeScroll();
                                    setCheckboxError(true);
                                    return;
                                }
                                handleBankIdRegistrationClick();
                            }}
                        >
                            {t('bank-id-module.link')}
                        </Button>
                    }
                >
                    <img
                        alt={'bank id verification'}
                        src={'/contents/bank_id_kyc_logo.png'}
                        className="u-block-center u-hide@s-up"
                    />
                    <img
                        alt={'bank id verification'}
                        src={'/contents/bank_id_kyc_logo.png'}
                        className="u-block-center u-mb-small c-logo c-logo--has-image u-hide@s"
                    />
                    {t('bank-id-module.text')}
                </Card>
            </Layout.Item>
        </>
    );
};
