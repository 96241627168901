import React from 'react';
import { useTranslation } from 'react-i18next';
import { Contract } from '@cp-cz/common';
import { Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';

type DebtNotificationProps = {
    contract: Contract;
};

export const DebtNotification: React.FC<DebtNotificationProps> = ({ contract }) => {
    const { t } = useTranslation('contracts');
    const { isActive, stateConsolidatedCode, details } = contract;

    if (!details?.financial?.outstandingBalance || !stateConsolidatedCode) return null;

    const getNotification = (status: NotificationStatus) => {
        return (
            <Notification
                status={status}
                testId={'notification-debt'}
                className={'u-mb'}
                text={t('debt-notification.text')}
            />
        );
    };

    if (
        (details.financial.outstandingBalance !== 0 && isActive && stateConsolidatedCode === 'FRZ') ||
        (details.financial.outstandingBalance < 0 && !isActive)
    ) {
        return getNotification(NotificationStatus.warning);
    } else if (details.financial.outstandingBalance > 0 && !isActive) {
        return getNotification(NotificationStatus.info);
    } else return null;
};
