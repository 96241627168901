import React from 'react';
import { useHistory } from 'react-router-dom';
import { landingPageUrl } from '../../config';
import { dashboardPagePath, loginRedirectPagePath } from '../../components/navigation/paths';
import { openInSameWindow, useAuthentication } from '@cp-shared-5/frontend-ui';

export const LandingPage: React.FC = () => {
    const { isAuthenticated } = useAuthentication();
    const history = useHistory();

    if (!isAuthenticated) {
        if (landingPageUrl) openInSameWindow(landingPageUrl)();
        else history.push(loginRedirectPagePath());
    } else history.push(dashboardPagePath());

    return null;
};
