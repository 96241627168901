import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Spinner } from '@cp-shared-5/frontend-ui';
import { useLogin } from 'auth/useLogin';
import { dashboardPagePath } from 'components/navigation/paths';

export type InvitationParams = {
    email?: string;
};

export const InvitationRedirect: React.FC = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    // example params for testing /invitation?language=cz&email=dev.unregister@mailinator.com
    const invitationParams: InvitationParams = {
        email: searchParams.get('email') ?? undefined,
    };
    const login = useLogin(dashboardPagePath, invitationParams.email);

    useEffect(() => {
        login();
    }, [login]);

    return <Spinner center={true} />;
};
