import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FaqUi } from './ui';
import { useFaq } from './useFaq';
import { useAnalyticsPageViewTracker, FaqLoadingPlaceholder } from '@cp-shared-5/frontend-ui';

const FaqWithHandlers = withLoadingAndNoConnectionHandler(FaqUi);

export const Faq: React.FC = () => {
    const { cmsContent: faq, isLoading, loadingError } = useFaq();

    useAnalyticsPageViewTracker('faq', !!faq);

    return (
        <FaqWithHandlers
            isLoading={isLoading}
            faq={faq}
            hasError={!!loadingError}
            loadingPlaceholder={<FaqLoadingPlaceholder withDescription />}
        />
    );
};
