import React from 'react';
import { FinancialDetails, InsuranceCoverage } from '@cp-cz/common';
import { DataOverview, DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';
import { useTranslationWithFormatting, TranslationFormat } from 'localization/useTranslationWithFormatting';

type PropertyDemageSectionProps = {
    coverage: InsuranceCoverage;
    financialDetails?: FinancialDetails;
};

export const PropertyDemageSection: React.FC<PropertyDemageSectionProps> = ({ coverage, financialDetails }) => {
    const { t, f } = useTranslationWithFormatting('insurance-details');
    const translationPrefix = 'property-demage-section';

    const { limitHealthAmt, limitPropertyAmt } = coverage;
    const { currencyCode } = financialDetails || {};

    const currencyCodeTranslation = currencyCode ? t(`translation:currency.${currencyCode}`) : undefined;

    if (!limitHealthAmt && !limitPropertyAmt) return null;

    const itemList: DefinitionListItem[] = [
        {
            label: t(`${translationPrefix}.limit-health-amount`),
            value: limitHealthAmt ? f(limitHealthAmt, TranslationFormat.CURRENCY, currencyCodeTranslation) : '-',
            testId: 'limit-health-amount',
        },
        {
            label: t(`${translationPrefix}.limit-property-amount`),
            value: limitPropertyAmt ? f(limitPropertyAmt, TranslationFormat.CURRENCY, currencyCodeTranslation) : '-',
            testId: 'limit-property-amount',
        },
    ];

    return (
        <DataOverview title={t(`${translationPrefix}.headline`)} withoutCardEffect>
            <DefinitionListHorizontal list={itemList} />
        </DataOverview>
    );
};
