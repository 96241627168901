import { CPDate, formatCpDate } from '@cp-shared-5/common-utilities';

export const getDateFrom = (
    povCoveragesCount: number,
    contractStartDate: CPDate,
    contractEndDate: CPDate,
    insuranceStartDate: CPDate,
    insuranceCompanyCode: string,
): CPDate => {
    if (povCoveragesCount === 1) {
        const currentDate = formatCpDate().toMoment();
        const startDate = formatCpDate(contractStartDate).toMoment();
        const endDate = formatCpDate(contractEndDate).toMoment();

        const isSameMonthAsContractEnd = currentDate.month() === endDate.month();
        const isAnniversary = currentDate.date() === startDate.date();
        const allianzValidToDate = endDate.date() + 1;
        const nonAllianzValidToDate = endDate.date() - 1;

        const isAllianz = insuranceCompanyCode === 'AL';

        const is1JanTo31Dec =
            startDate.month() + 1 === 1 &&
            startDate.date() === 1 &&
            endDate.month() + 1 === 12 &&
            endDate.date() === 31;

        const yearDifference =
            currentDate.month() > endDate.month() ||
            (isSameMonthAsContractEnd && currentDate.date() > allianzValidToDate && isAllianz) ||
            (isSameMonthAsContractEnd && currentDate.date() > nonAllianzValidToDate && !isAllianz && isAnniversary) ||
            (isSameMonthAsContractEnd && currentDate.date() > endDate.date() && !isAllianz)
                ? 0
                : 1;

        const newStartYearDate = currentDate.subtract(is1JanTo31Dec ? 0 : yearDifference, 'year');

        return formatCpDate(startDate.year(newStartYearDate.year())).toCpDate();
    } else {
        return insuranceStartDate;
    }
};

export const getDateTo = (validFrom: CPDate, insuranceCompanyCode?: string): CPDate => {
    const fromDate = formatCpDate(validFrom).toMoment();

    switch (insuranceCompanyCode) {
        case 'AL':
            return formatCpDate(fromDate.add(1, 'year')).toCpDate();
        case 'GCP':
        case 'GEN':
        case 'UN':
            return formatCpDate(fromDate.add(1, 'year').subtract(1, 'day')).toCpDate();
        default:
            return '';
    }
};

export const getFinalDatesFromTo = (
    dateFrom: CPDate,
    dateTo: CPDate,
): { finalDateFrom: CPDate; finalDateTo: CPDate } => {
    const fromDateMoment = formatCpDate(dateFrom).toMoment();
    const dateToMoment = formatCpDate(dateTo).toMoment();

    if (formatCpDate().toMoment().isAfter(dateToMoment, 'day')) {
        return {
            finalDateFrom: formatCpDate(fromDateMoment.add(1, 'year')).toCpDate(),
            finalDateTo: formatCpDate(dateToMoment.add(1, 'year')).toCpDate(),
        };
    }
    return { finalDateFrom: dateFrom, finalDateTo: dateTo };
};
