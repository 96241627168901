import React from 'react';
import { useTranslation } from 'react-i18next';
import { AddressBase } from '@cp-cz/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-5/frontend-ui';

export const getStreetName = (street: string | undefined): string | undefined => {
    if (!street) return;
    return street.split(' ').length > 1 ? street.substr(0, street.lastIndexOf(' ')) : street;
};

export const getStreetNumber = (street: string | undefined): string | undefined => {
    if (!street) return;
    return street.split(' ').length > 1 ? street.split(' ').pop() : undefined;
};

export const AddressList: React.FC<{ address: AddressBase }> = ({ address = {} }) => {
    const { t } = useTranslation('my-profile');

    const { street, zipCode, city } = address;

    const addressList: DefinitionListItem[] = [
        {
            label: t('addresses-section.city'),
            value: city || '-',
            testId: 'address-city',
        },
        {
            label: t('addresses-section.zip-code'),
            value: zipCode || '-',
            testId: 'address-zip-code',
        },
        {
            label: t('addresses-section.street'),
            value: getStreetName(street) || '-',
            testId: 'address-street',
        },
        {
            label: t('addresses-section.street-number'),
            value: getStreetNumber(street) || '-',
            testId: 'address-street-number',
        },
    ];

    return <DefinitionListHorizontal list={addressList} />;
};
