import { Base64 } from './base64';
import { CPDate } from '@cp-shared-5/common-utilities';
import { Insurance, ProductTypeLevel3 } from './contracts';
import { MyProfileData } from './my-profile';

export type Document = {
    contractNumber: string;
    documentType: DocumentType;
    _downloadLink: string;
    invoiceId?: string;
    issueDate?: string;
};

export enum DocumentTypeCode {
    /**
     * Technical certificate for the car
     */
    TP = 'TP',
    /**
     * Regular (monthly) invoice
     */
    DDR = 'DDR',
    /**
     * Financial (ad-hoc) invoice
     */
    DDF = 'DDF',
    /**
     * Credit note
     */
    DDD = 'DDD',
}

export type Documents = {
    documents: Document[];
};

export enum DocumentType {
    GENERAL_TERMS = 'GENERAL_TERMS',
    GREEN_CARD = 'GREEN_CARD',
    GREEN_CARD_UPCOMING = 'GREEN_CARD_UPCOMING',
    DRIVER_SET = 'DRIVER_SET',
    COPY_OF_REGISTRATION_CERTIFICATE = 'COPY_OF_REGISTRATION_CERTIFICATE',
    INVOICE_MONTHLY = 'INVOICE_MONTHLY',
    INVOICE_AD_HOC = 'INVOICE_AD_HOC',
    CREDIT_NOTE = 'CREDIT_NOTE',
    ABROAD_TRAVEL = 'ABROAD_TRAVEL',
    DEVINCULATION_DOCUMENT = 'DEVINCULATION_DOCUMENT',
}

export type DocumentNode = {
    data: Base64;
};

export type DocumentError = 'NOT_FOUND' | 'FILE_CONTENT_NOT_EXIST';

export enum GreenCardPdfTemplate {
    ALLIANZ_BRANDED = 'Allianz_branded',
    ALLIANZ_NON_BRANDED = 'Allianz_non_branded',
    GCP_BRANDED = 'GCP_branded',
    GCP_NON_BRANDED = 'GCP_non_branded',
    UNIQA = 'UNIQA',
    NONE = 'NONE',
}

export type GreenCardData = {
    dateFrom?: CPDate;
    dateTo?: CPDate;
    insurance?: Insurance;
    registrationNumber?: string;
    vehicleCategoryCode?: string;
    vehicleMake?: string;
    brand?: string;
    productTypeLevel3?: ProductTypeLevel3;
    profileData?: MyProfileData;
};
