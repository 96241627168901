import React from 'react';
import { useTranslation } from 'react-i18next';
import { Hr } from '@vwfs-bronson/bronson-react';
import { AccordionItem } from '@cp-shared-5/frontend-ui';
import { FinancialDetails, Insurance } from '@cp-cz/common';
import { InsuranceDetailsUi } from './ui';

type InsuranceDetailsProps = {
    insuranceDetails?: Insurance[];
    financialDetails?: FinancialDetails;
};

export const InsuranceDetails: React.FC<InsuranceDetailsProps> = ({ insuranceDetails, financialDetails }) => {
    const { t } = useTranslation('insurance-details');

    if (!insuranceDetails || !insuranceDetails.length) {
        return null;
    }

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-xsmall'}>
            {insuranceDetails.map((insurance, index) => (
                <div key={insurance.contractInsuranceNumber}>
                    {index !== 0 && <Hr className="u-mb-none" />}
                    <InsuranceDetailsUi insurance={insurance} financialDetails={financialDetails} />
                </div>
            ))}
        </AccordionItem>
    );
};
