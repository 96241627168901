import React from 'react';
import { ContractIdentifierFilterItem, DocumentTypeFilterItem, TimeFrameFilterItem } from '@cp-shared-5/frontend-ui';
import { CPDate, formatCpDate } from '@cp-shared-5/common-utilities';
import {
    Contract,
    ContractTerminationReason,
    CoverageCode,
    DevinculationDocumentShortcode,
    Document,
    DocumentType,
    EndOfPledgeOptions,
    getAbroadTravelRequestSendingEndpoint,
    getDevinculationRequestSendingEndpoint,
    getGreenCardPdfEndpoint,
    GreenCardData,
    Insurance,
    InsuranceCoverage,
    MyProfileData,
    ProductTypeLevel3,
} from '@cp-cz/common';
import { TFunction } from 'i18next';
import { kebabCase } from 'lodash';
import { getDateFrom, getDateTo, getFinalDatesFromTo } from './greenCardUtils';
import moment from 'moment';
import { getAbroadTravelContracts, getDevinculationContracts } from '../open-request/form-view/utils';

export type MyDocument = {
    contractNumber: string;
    encryptedContractNumber?: string;
    contractStartDate?: CPDate;
    documentType: string;
    categoryType: string;
    productName?: string;
    invoiceId?: string;
    issueDate?: string;
    brandModelType?: string;
    licensePlate?: string;
    vin?: string;
    generalTermsCode?: string;
    _downloadLink: string;
    greenCardData?: GreenCardData;
    devinculationShortcode?: string;
    devinculationEndOfPledge?: EndOfPledgeOptions;
};
const RETAIL_CLIENTS = ['RET', 'CBR', 'CGL', 'CSV', 'RTL'];
const FLEET_CLIENTS = ['FLB', 'FLG', 'FLS', 'FRN', 'IFL', 'LMC', 'REN', 'TAX', 'FLT'];
const GREEN_CARD_EXCLUDING_TERMINATION_REASONS = [
    ContractTerminationReason.S,
    ContractTerminationReason.VE,
    ContractTerminationReason.VN,
    ContractTerminationReason.AK,
    ContractTerminationReason.K,
    ContractTerminationReason.PV,
    ContractTerminationReason.PB,
    ContractTerminationReason.VP,
];

export const sortDocuments = (myDocumentsData: MyDocument[]): MyDocument[] => {
    return [...myDocumentsData].sort(
        (a, b) => formatCpDate(b.contractStartDate).unix() - formatCpDate(a.contractStartDate).unix(),
    );
};

export const getContractFilterItems = (
    documents: MyDocument[],
    contractItemGetter: (document: MyDocument) => React.ReactNode,
): ContractIdentifierFilterItem[] => {
    return documents.reduce(
        (acc: ContractIdentifierFilterItem[], document: MyDocument): ContractIdentifierFilterItem[] => {
            if (document.contractNumber && !acc.some((filterItem) => filterItem.value === document.contractNumber)) {
                acc.push({
                    contractIdentifier: document.contractNumber || '',
                    value: document.contractNumber || '',
                    reactNode: contractItemGetter(document),
                });
            }
            return acc;
        },
        [],
    );
};

export const getDocumentTypeFilterItems = (documents: MyDocument[]): DocumentTypeFilterItem[] => {
    return documents.reduce((acc: DocumentTypeFilterItem[], document: MyDocument): DocumentTypeFilterItem[] => {
        if (!acc.some((filterItem) => filterItem.value === document.categoryType)) {
            acc.push({
                documentType: document.categoryType,
                value: document.categoryType,
            });
        }
        return acc;
    }, []);
};

export const getTimeFrameFilterItems = (t: TFunction): TimeFrameFilterItem[] => {
    const translationPrefix = 'filters.time-period';
    const currentDate = formatCpDate().toMoment();
    return [
        {
            from: formatCpDate().subtract(1, 'months').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.1-month`),
            key: '1m',
        },
        {
            from: formatCpDate().subtract(3, 'months').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.3-months`),
            key: '3m',
        },
        {
            from: formatCpDate().subtract(1, 'years').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.1-year`),
            key: '1y',
        },
        {
            from: formatCpDate().subtract(5, 'years').toMoment(),
            to: currentDate,
            value: t(`${translationPrefix}.5-years`),
            key: '5y',
        },
    ];
};

export const getGeneralTermsDocuments = (contracts: Contract[], t: TFunction): MyDocument[] => {
    const myDocs: MyDocument[] = [];
    contracts
        .filter((c) => c.details.financial?.generalTermsCode)
        .forEach((c) => {
            myDocs.push({
                contractStartDate: c.details.financial?.startDate,
                productName: t(`contracts:product-type-level-3.${c.productTypeLevel3}`),
                documentType: DocumentType.GENERAL_TERMS,
                contractNumber: c.contractNumber,
                categoryType: t(`my-documents:filters.document-type.${kebabCase(DocumentType.GENERAL_TERMS)}`),
                brandModelType: `${c.details.vehicle?.brand} ${c.details.vehicle?.model} ${c.details.vehicle?.vehicleType}`,
                licensePlate: c.details.vehicle?.licensePlate,
                generalTermsCode: c.details.financial?.generalTermsCode,
                _downloadLink: `documents/financial-details/${c.details.financial?.generalTermsCode}.pdf`,
            } as MyDocument);
        });
    return myDocs;
};

export const getDriverSetDocument = (
    contracts: Contract[],
    t: TFunction,
    myProfileData?: MyProfileData,
): MyDocument[] => {
    if (myProfileData === undefined) return [];

    // documentId is used to fetch documents from Fasttrack
    // currently all files are fetched from local
    // let documentId: string;
    let downloadLink: string;
    let documentType: string;
    if (myProfileData.identification?.segmentation) {
        if (RETAIL_CLIENTS.includes(myProfileData.identification.segmentation)) {
            downloadLink =
                'https://www.vwfs.cz/content/dam/bluelabel/valid/www-vwfs-cz/dokumenty-ke-stazeni/produktova-stranka-ol/ol/manual-ridice.pdf';
            // documentId = 'driverSetRTL';
            documentType = `${kebabCase(DocumentType.DRIVER_SET)}-retail`;
        } else if (FLEET_CLIENTS.includes(myProfileData.identification.segmentation)) {
            downloadLink =
                'https://www.vwfs.cz/content/dam/bluelabel/valid/www-vwfs-cz/dokumenty-ke-stazeni/produktova-stranka-ol/ol/driver-set.pdf';
            // documentId = 'driverSetFLT';
            documentType = `${kebabCase(DocumentType.DRIVER_SET)}-fleet`;
        } else return [];
    } else return [];

    const activeContracts = [...contracts].filter((contract) => contract.isActive);
    const latestContract =
        activeContracts.length !== 0
            ? [...activeContracts].sort(
                  (a, b) =>
                      formatCpDate(b.details.financial?.startDate).unix() -
                      formatCpDate(a.details.financial?.startDate).unix(),
              )[0]
            : [...contracts].sort(
                  (a, b) =>
                      formatCpDate(b.details.financial?.startDate).unix() -
                      formatCpDate(a.details.financial?.startDate).unix(),
              )[0];

    const driverSetDocument: MyDocument = {
        contractStartDate: latestContract.details.financial?.startDate,
        productName: t(`contracts:product-type-level-3.${latestContract.productTypeLevel3}`),
        documentType: documentType,
        contractNumber: latestContract.contractNumber,
        categoryType: t(`my-documents:filters.document-type.${kebabCase(DocumentType.DRIVER_SET)}`),
        brandModelType: `${latestContract.details.vehicle?.brand} ${latestContract.details.vehicle?.model} ${latestContract.details.vehicle?.vehicleType}`,
        licensePlate: latestContract.details.vehicle?.licensePlate,
        generalTermsCode: latestContract.details.financial?.generalTermsCode,
        // use this if document will be fetched from fasttrack
        // _downloadLink: getDocumentEndpoint(documentId),
        _downloadLink: downloadLink,
    };
    return [driverSetDocument];
};

const prepareDocumentsForContract = (documents: Document[], contract: Contract, t: TFunction): MyDocument[] => {
    const matchedDocuments = documents.filter((doc) => doc.contractNumber === contract.contractNumber);
    const documentForContract: MyDocument[] = [];

    for (const document of matchedDocuments) {
        documentForContract.push({
            contractStartDate: contract.details.financial?.startDate,
            productName: t(`contracts:product-type-level-3.${contract.productTypeLevel3}`),
            documentType: document.documentType,
            contractNumber: contract.contractNumber,
            categoryType: t(`my-documents:filters.document-type.${kebabCase(document.documentType)}`),
            brandModelType: `${contract.details.vehicle?.brand} ${contract.details.vehicle?.model} ${contract.details.vehicle?.vehicleType}`,
            licensePlate: contract.details.vehicle?.licensePlate,
            invoiceId: document?.invoiceId,
            issueDate: document?.issueDate,
            _downloadLink: document._downloadLink,
        } as MyDocument);
    }

    return documentForContract;
};

export const getAllDocumentsWithDocumentType = (
    contracts: Contract[],
    t: TFunction,
    documents?: Document[],
): MyDocument[] => {
    if (!documents) return [];
    const myDocs: MyDocument[] = [];

    contracts.forEach((c) => {
        const documentsForContract = prepareDocumentsForContract(documents, c, t);
        myDocs.push(...documentsForContract);
    });

    return myDocs;
};

export const getGreenCardDocuments = (
    contracts: Contract[],
    t: TFunction,
    myProfileData: MyProfileData | undefined,
): MyDocument[] => {
    if (
        myProfileData &&
        myProfileData.identification?.segmentation &&
        [...RETAIL_CLIENTS, 'SME'].includes(myProfileData.identification.segmentation)
    ) {
        const greenCards = contracts
            .filter((c) => c.isActive)
            .filter((c) => c.details.insurances)
            .filter(
                (c) =>
                    !c.contractTerminationReason ||
                    (c.contractTerminationReason &&
                        !GREEN_CARD_EXCLUDING_TERMINATION_REASONS.includes(c.contractTerminationReason)),
            )
            .filter(
                (c) =>
                    formatCpDate()
                        .toMoment()
                        .isSameOrAfter(formatCpDate(c.details?.financial?.startDate).toCpDate(), 'day') &&
                    formatCpDate().toMoment().isSameOrBefore(c.details?.financial?.endDate, 'day'),
            )
            .map((c) => {
                const povInsurances = c.details.insurances?.filter(
                    (i: Insurance) =>
                        i.recordStateCode === 'L' &&
                        i.coverages.some(
                            (cov: InsuranceCoverage) =>
                                cov.coverageCode === CoverageCode.POV &&
                                (!cov.coverageEndDate || formatCpDate().toMoment().isSameOrBefore(cov.coverageEndDate)),
                        ),
                );

                if (!povInsurances || !povInsurances.length)
                    return {
                        contractNumber: '',
                        documentType: '',
                        categoryType: '',
                        _downloadLink: '',
                        greenCardData: undefined,
                    };

                const sortedInsurancesFromYoungest = [...(povInsurances || [])].sort((a, b) => {
                    if (moment(a.insuranceStartDate).isBefore(moment(b.insuranceStartDate))) return 1;
                    if (moment(a.insuranceStartDate).isAfter(moment(b.insuranceStartDate))) return -1;
                    return 0;
                });
                const relevantInsurance =
                    (sortedInsurancesFromYoungest.length && sortedInsurancesFromYoungest[0]) || undefined;

                const dateFrom = getDateFrom(
                    povInsurances.length,
                    c.details.financial?.startDate || '',
                    c.details.financial?.endDate || '',
                    relevantInsurance?.insuranceStartDate || '',
                    relevantInsurance?.insuranceCompanyCode || '',
                );
                const dateTo = getDateTo(dateFrom, relevantInsurance?.insuranceCompanyCode);
                const { finalDateFrom, finalDateTo } = getFinalDatesFromTo(dateFrom, dateTo);

                return {
                    contractNumber: c.contractNumber,
                    documentType: DocumentType.GREEN_CARD,
                    categoryType: t(`my-documents:filters.document-type.${kebabCase(DocumentType.GREEN_CARD)}`),
                    _downloadLink: getGreenCardPdfEndpoint(),
                    contractStartDate: finalDateFrom,
                    productName: t(`contracts:product-type-level-3.${c.productTypeLevel3}`),
                    licensePlate: c.details.vehicle?.licensePlate || c.details.vehicle?.vin || '',
                    greenCardData: {
                        dateFrom: finalDateFrom,
                        dateTo: finalDateTo,
                        insurance: relevantInsurance,
                        registrationNumber: c.details.vehicle?.licensePlate || c.details.vehicle?.vin || '',
                        vehicleCategoryCode: c.details.vehicle?.vehicleCategoryCode,
                        vehicleMake: `${c.details.vehicle?.brand || ''} ${c.details.vehicle?.model || ''}`,
                        brand: c.details.vehicle?.brand,
                        productTypeLevel3: c.productTypeLevel3,
                        profileData: myProfileData,
                    },
                };
            })
            .filter((doc) => doc.greenCardData);

        const upcomingGreenCards = greenCards.reduce((acc: MyDocument[], greenCard: MyDocument) => {
            if (
                formatCpDate()
                    .toMoment()
                    .isSameOrAfter(formatCpDate(greenCard.greenCardData?.dateTo).toMoment().subtract(60, 'days'), 'day')
            ) {
                const upcomingDateFrom = formatCpDate(
                    formatCpDate(greenCard.greenCardData?.dateFrom).toMoment().add(1, 'year'),
                ).toCpDate();

                return [
                    ...acc,
                    {
                        ...greenCard,
                        contractStartDate: upcomingDateFrom,
                        documentType: DocumentType.GREEN_CARD_UPCOMING,
                        greenCardData: {
                            dateFrom: upcomingDateFrom,
                            dateTo: formatCpDate(
                                formatCpDate(greenCard.greenCardData?.dateTo).toMoment().add(1, 'year'),
                            ).toCpDate(),
                            insurance: greenCard.greenCardData?.insurance || undefined,
                            registrationNumber: greenCard.greenCardData?.registrationNumber,
                            vehicleCategoryCode: greenCard.greenCardData?.vehicleCategoryCode,
                            vehicleMake: greenCard.greenCardData?.vehicleMake || '',
                            brand: greenCard.greenCardData?.brand,
                            productTypeLevel3: greenCard.greenCardData?.productTypeLevel3 || ProductTypeLevel3.UCW,
                            profileData: myProfileData,
                        },
                    },
                ];
            }
            return acc;
        }, []);

        return [...greenCards, ...upcomingGreenCards];
    }
    return [];
};

export const getAbroadTravelCertificationDocuments = (contracts: Contract[], t: TFunction) => {
    const myDocs: MyDocument[] = [];

    const abroadTravelContracts = getAbroadTravelContracts(contracts);

    abroadTravelContracts.forEach((c) => {
        myDocs.push({
            contractStartDate: c.details.financial?.startDate,
            productName: t(`contracts:product-type-level-3.${c.productTypeLevel3}`),
            documentType: DocumentType.ABROAD_TRAVEL,
            contractNumber: c.contractNumber,
            encryptedContractNumber: c.encryptedContractNumber,
            categoryType: t(`my-documents:filters.document-type.${kebabCase(DocumentType.ABROAD_TRAVEL)}`),
            brandModelType: `${c.details.vehicle?.brand} ${c.details.vehicle?.model} ${c.details.vehicle?.vehicleType}`,
            licensePlate: c.details.vehicle?.licensePlate,
            _downloadLink: getAbroadTravelRequestSendingEndpoint(),
        });
    });

    return myDocs;
};

export const getDevinculationDocuments = (contracts: Contract[], t: TFunction) => {
    const myDocs: MyDocument[] = [];

    const devinculationContracts = getDevinculationContracts(contracts);

    devinculationContracts.forEach((c: Contract) => {
        const devinculationDocumentCommonData: MyDocument = {
            contractStartDate: c.details.financial?.startDate,
            productName: t(`contracts:product-type-level-3.${c.productTypeLevel3}`),
            documentType: DocumentType.DEVINCULATION_DOCUMENT,
            contractNumber: c.contractNumber,
            encryptedContractNumber: c.encryptedContractNumber,
            categoryType: t(`my-documents:filters.document-type.${kebabCase(DocumentType.DEVINCULATION_DOCUMENT)}`),
            brandModelType: `${c.details.vehicle?.brand} ${c.details.vehicle?.model} ${c.details.vehicle?.vehicleType}`,
            licensePlate: c.details.vehicle?.licensePlate,
            vin: c.details.vehicle?.vin,
            _downloadLink: getDevinculationRequestSendingEndpoint(),
        };

        const devinculationInFavorOfCustomerInvoice: MyDocument = {
            ...devinculationDocumentCommonData,
            devinculationShortcode: DevinculationDocumentShortcode.IN_FAVOR_OF_CUSTOMER_INVOICE,
            devinculationEndOfPledge: EndOfPledgeOptions.CUSTOMER_INVOICE,
        };
        const devinculationInFavorOfCustomerBudgeting: MyDocument = {
            ...devinculationDocumentCommonData,
            devinculationShortcode: DevinculationDocumentShortcode.IN_FAVOR_OF_CUSTOMER_BUDGETING,
            devinculationEndOfPledge: EndOfPledgeOptions.CUSTOMER_BUDGET,
        };
        const devinculationInFavorOfAuthorizedService: MyDocument = {
            ...devinculationDocumentCommonData,
            devinculationShortcode: DevinculationDocumentShortcode.IN_FAVOR_OF_AUTHORIZED_SERVICE,
            devinculationEndOfPledge: EndOfPledgeOptions.AUTHORIZED_SERVICE,
        };
        const devinculationInFavorOfNotAuthorizedService: MyDocument = {
            ...devinculationDocumentCommonData,
            devinculationShortcode: DevinculationDocumentShortcode.IN_FAVOR_OF_NOT_AUTHORIZED_SERVICE,
            devinculationEndOfPledge: EndOfPledgeOptions.NON_AUTHORIZED_SERVICE,
        };

        myDocs.push(
            devinculationInFavorOfCustomerInvoice,
            devinculationInFavorOfCustomerBudgeting,
            devinculationInFavorOfAuthorizedService,
            devinculationInFavorOfNotAuthorizedService,
        );
    });

    return myDocs;
};

export const getAbroadTravelDocumentSubject = (document: MyDocument, t: TFunction) => {
    const translationVariables = {
        licensePlate: document.licensePlate,
        brandAndModel: document.brandModelType,
        vin: document.vin,
    };

    const translationKey = document.licensePlate ? 'abroad-travel' : 'abroad-travel-vin';

    return t(`document-name.${translationKey}`, translationVariables);
};

export const getDevinculationDocumentSubject = (document: MyDocument, t: TFunction) => {
    const translationVariables = {
        licensePlate: document.licensePlate,
        brandAndModel: document.brandModelType,
        vin: document.vin,
    };

    const translationKeyMapping = {
        [DevinculationDocumentShortcode.IN_FAVOR_OF_CUSTOMER_BUDGETING]: 'devinculation-in-favor-of-customer-budgeting',
        [DevinculationDocumentShortcode.IN_FAVOR_OF_CUSTOMER_INVOICE]: 'devinculation-in-favor-of-customer-invoice',
        [DevinculationDocumentShortcode.IN_FAVOR_OF_AUTHORIZED_SERVICE]: 'devinculation-in-favor-of-authorized-service',
        [DevinculationDocumentShortcode.IN_FAVOR_OF_NOT_AUTHORIZED_SERVICE]:
            'devinculation-in-favor-of-not-authorized-service',
    };

    let translationKey = translationKeyMapping[document.devinculationShortcode as DevinculationDocumentShortcode];

    if (!document.licensePlate) {
        translationKey += '-vin';
    }

    return t(`document-name.${translationKey}`, translationVariables);
};
