import { CPDate } from '@cp-shared-5/common-utilities';
import { CurrencyCode } from './financial-details';

export enum InstallmentTypeCode {
    CAP = 'CAP',
    INT = 'INT',
    SRV = 'SRV',
    INS = 'INS',
    TOTAL = 'TOTAL',
    ACI = 'ACI',
}

export enum InstallmentStateCode {
    L = 'L',
}

export type Installment = {
    installmentNumber?: number;
    dueDate?: CPDate;
    interestAmount?: number;
    currencyCode?: CurrencyCode;
    installmentTypeCode?: InstallmentTypeCode;
    installmentStateCode?: InstallmentStateCode;
};

export type GroupedInstallment = {
    installmentNumber: number;
    dueDate?: CPDate;
    principalSum?: number;
    interest?: number;
    servicesFee?: number;
    insurance?: number;
    total?: number;
    currencyCode?: CurrencyCode;
};

export type AmortizationDetails = {
    numberOfInstallments?: number;
    installmentsPastDueCount?: number;
    installments?: Array<Installment>;
};
