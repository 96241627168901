import { CPDate } from '@cp-shared-5/common-utilities';

export enum FuelTypeCode {
    DIE = 'DIE', //Diesel
    PET = 'PET', //Petrol
    LPG = 'LPG', //Liquified petroleum gas
    CNG = 'CNG', //Compressed Natural Gas,
}

export type VehicleDetails = {
    vin?: string;
    licensePlate?: string;
    logBookID?: string;
    depositedVWFSFlag?: 'Y' | 'N';
    logBookOwnerName?: string;
    registrationDate?: CPDate;
    stkDate?: CPDate;
    contractualMileageTotalKM?: number;
    contractualMileageToleranceKM?: number;
    brand?: string;
    model?: string;
    vehicleType?: string;
    vehicleCategoryCode?: string;
    fuelTypeCode?: FuelTypeCode;
};
