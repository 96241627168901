import { IconFooter } from '@cp-cz/common';
import { IconFooter as IconFooterShared, useAnalyticsActionTracker, useAuthentication } from '@cp-shared-5/frontend-ui';
import { IconFooterItemCMS } from '@cp-shared-5/apis';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { getCurrentPageName } from '../../../../utils';

export const IconFooterUi: React.FC<{ iconFooter?: IconFooter }> = ({ iconFooter }) => {
    const location = useLocation();
    const currentPageName = getCurrentPageName(location);
    const { isAuthenticated } = useAuthentication();

    const { onAction: onCallUs } = useAnalyticsActionTracker('onClickCallUsLink');
    const { onAction: onEmailUs } = useAnalyticsActionTracker('onClickEmailLink');

    if (!iconFooter) {
        return null;
    }

    let iconFooterItems: IconFooterItemCMS[];

    if (!isAuthenticated) {
        iconFooterItems = iconFooter.items.filter((icon) => icon.icon !== 'semantic-mail');
    } else iconFooterItems = iconFooter.items;

    return (
        <IconFooterShared
            items={iconFooterItems}
            trackingFunctions={{
                callUs: (): void => onCallUs('Call us', currentPageName),
                emailUs: (): void => onEmailUs('Contact', currentPageName),
            }}
        />
    );
};
