import React from 'react';
import { FinancialDetailsUi } from './ui';
import { useTranslation } from 'react-i18next';
import { AccordionItem } from '@cp-shared-5/frontend-ui';
import {
    ProductTypeLevel3,
    FinancialDetails as FinancialDetailsType,
    InstallmentCreditFinancialDetails,
} from '@cp-cz/common';
import { NoConnectionNotification } from '../../../notifications/no-connection/NoConnectionNotification';

export type FinancialDetailsProps = {
    financialDetails?: FinancialDetailsType;
    productTypeLevel3?: ProductTypeLevel3;
};

export const FinancialDetails: React.FC<FinancialDetailsProps> = ({ financialDetails, productTypeLevel3 }) => {
    const { t } = useTranslation('financial-details');
    const hasError = !financialDetails;
    const areAllFieldsEmpty =
        !financialDetails?.currencyCode &&
        !financialDetails?.startDate &&
        !financialDetails?.endDate &&
        !financialDetails?.currentContractAgeMonths &&
        !financialDetails?.generalTermsCode &&
        !(financialDetails as InstallmentCreditFinancialDetails)?.financeValueOfContract &&
        !(financialDetails as InstallmentCreditFinancialDetails)?.downPayment &&
        !(financialDetails as InstallmentCreditFinancialDetails)?.balloonAmount;

    if (areAllFieldsEmpty && !hasError) {
        return null;
    }

    return (
        <AccordionItem title={t('headline')} contentClassName={'u-pr-none'}>
            {hasError ? (
                <NoConnectionNotification />
            ) : (
                <FinancialDetailsUi productTypeLevel3={productTypeLevel3} financialDetails={financialDetails} />
            )}
        </AccordionItem>
    );
};
