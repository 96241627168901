import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { OpenRequestUi } from './ui';
import { RequestLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';
import { useContracts } from '../contracts';

const OpenRequestWithHandlers = withLoadingAndNoConnectionHandler(OpenRequestUi);

export const OpenRequest: React.FC = () => {
    const { data: contracts, isLoading: isContractsLoading, loadingError: contractsLoadingError } = useContracts();

    useAnalyticsPageViewTracker('requests', !!contracts);

    return (
        <OpenRequestWithHandlers
            isLoading={isContractsLoading}
            contracts={contracts}
            hasError={!!contractsLoadingError}
            loadingPlaceholder={<RequestLoadingPlaceholder />}
        />
    );
};
