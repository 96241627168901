import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { PrivacyPolicyUi } from './ui';
import { usePrivacyPolicy } from './usePrivacyPolicy';
import { StaticPageLoadingPlaceholder, useAnalyticsPageViewTracker } from '@cp-shared-5/frontend-ui';

const PrivacyPolicyWithHandlers = withLoadingAndNoConnectionHandler(PrivacyPolicyUi);

export const PrivacyPolicy: React.FC = () => {
    const { cmsContent: privacyPolicy, isLoading, loadingError } = usePrivacyPolicy();

    useAnalyticsPageViewTracker('privacyPolicy', !!privacyPolicy);

    return (
        <PrivacyPolicyWithHandlers
            isLoading={isLoading}
            privacyPolicy={privacyPolicy}
            hasError={!!loadingError}
            loadingPlaceholder={<StaticPageLoadingPlaceholder />}
        />
    );
};
