import { FinancialDetails } from './financial-details';
import { VehicleDetails } from './vehicle-details';
import { Insurance } from './insurance';
import { Service } from './service';
import { ContractParties } from './contract-parties';

export enum ProductTypeLevel3 {
    OL = 'OL', //Operating lease
    FL = 'FL', //Finance lease
    EQPL = 'EQPL', //Equipment lease
    RELL = 'RELL', //Real estate lease
    RELF = 'RELF', //Real estate Financing
    ICVBW = 'ICVBW', //Instalment Credit (vehicle based with return option)
    ICVBWO = 'ICVBWO', //Instalment Credit (vehicle based without return option)
    ICNVB = 'ICNVB', //Instalment Credit (non vehicle based)
    FSV = 'FSV', //Stock finance
    FACT = 'FACT', //Factoring (nonrecourse and recourse)
    INVF = 'INVF', //Investment Financing
    REVC = 'REVC', //Revolving credit
    INTF = 'INTF', //Interim Financing
    OADVP = 'OADVP', //Other Advance Payments
    MIPI = 'MIPI', //Motor Insurance (with financing)
    GAPPI = 'GAPPI', //Guaranteed Asset Protection (with financing)
    CPI = 'CPI', // Creditor Protection Insurance
    AST = 'AST', //Assistant
    MIINT = 'MIINT', //Motor Insurance (without financing)
    GAPINT = 'GAPINT', //Guaranteed Asset Protection (without financing)
    UCW = 'UCW', //Warranty Insurance
    SRVFM = 'SRVFM', //Service and Full Maintenance
    RASS = 'RASS', //Road Asistance
    SRVLM = 'SRVLM', //Service and Limited Maintenance
    SRV = 'SRV', // Service
    TYRES = 'TYRES', //Tyres replacement and storage
    TCARD = 'TCARD', //Fuel Card,
    ELBOOK = 'ELBOOK', //Electronic logbook
    CCAR = 'CCAR', //Courtesy car
    PICKUP = 'PICKUP', //Pick-up service
    HFEE = 'HFEE', //Highway fee
    CLAIM = 'CLAIM', //'Claims management'
}

export enum StateConsolidatedCode {
    ACTPRO = 'ACTPRO', //Active
    ACTCTR = 'ACTCTR', //Active
    FRZ = 'FRZ', //Frozen
    PEN = 'PEN', //Pending
    TER = 'TER', //Terminated
    ETER = 'ETER', //Early terminated
    CANCTR = 'CANCTR', //Cancelled
    CANPRO = 'CANPRO', //Cancelled
    STDBY = 'STDBY', //Stand by
    REJ = 'REJ', //Rejected
    RDY4HO = 'RDY4HO', //Ready for Handover/Contract
    APR = 'APR', //Approved
    INA = 'INA', //In Analysis
    PRO = 'PRO', //Proposal
    EVA = 'EVA', //To Evaluation
    CON = 'CON', //Confirmed
    INI = 'INI', //Initial
}

export enum ContractCategory {
    QUOTATION_OFFER = 'quotation / offer',
    PROPOSAL_APPLICATION = 'proposal / application',
    CONTRACT = 'contract',
}

export enum ContractTerminationReason {
    S = 'S',
    VE = 'VE',
    VN = 'VN',
    AK = 'AK',
    K = 'K',
    PV = 'PV',
    PB = 'PB',
    VP = 'VP',
    DSO = 'DSO',
    UKD = 'UKD',
    T = 'T',
    CP = 'CP',
    FV = 'FV',
    PP = 'PP',
    TT = 'TT',
    XX = 'XX',
    VYS = 'VYS',
    VYK = 'VYK',
}

export type Contract = {
    isActive: boolean;
    contractNumber: string;
    encryptedContractNumber: string;
    productTypeLevel3?: ProductTypeLevel3;
    stateConsolidatedCode?: StateConsolidatedCode;
    contractCategoryName?: ContractCategory;
    contractTerminationReason?: ContractTerminationReason;
    details: ContractDetails;
    transactionId?: string;
};

export type ContractDetails = {
    financial?: FinancialDetails;
    vehicle?: VehicleDetails;
    insurances?: Insurance[];
    services?: Service[];
    contractParties?: ContractParties;
};
