import React, { ChangeEvent } from 'react';
import { Layout, RadioButton, FormField, Fieldset, ErrorMessage } from '@vwfs-bronson/bronson-react';
import { ValidatedSelect, ValidatedTextarea } from '@cp-shared-5/frontend-ui';
import { useTranslation } from 'react-i18next';
import { Contract, EndOfTermCalculationReason } from '@cp-cz/common';
import { getEndOfTermCalculationReasonRadioItems, mapContractsToCustomSelectItems } from '../utils';
import { EndOfTermCalculationResultType } from '../initialValues';
import { FormikErrors, FormikTouched } from 'formik';

type EndOfTermCalculationFormSectionProps = {
    endOfTermCalculationValues: EndOfTermCalculationResultType;
    contracts: Contract[];
    setFieldValue: (m: string, v: string) => void;
    errors?: FormikErrors<EndOfTermCalculationResultType>;
    touched?: FormikTouched<EndOfTermCalculationResultType>;
};

export const EndOfTermCalculationFormSection: React.FC<EndOfTermCalculationFormSectionProps> = ({
    endOfTermCalculationValues,
    contracts,
    setFieldValue,
    errors,
    touched,
}) => {
    const { t } = useTranslation('open-request');
    const reasonHasError = !!errors?.reason && !!touched?.reason;
    const customSelectItems = mapContractsToCustomSelectItems(contracts, t);

    return (
        <>
            <Layout.Item>
                <ValidatedSelect
                    label={t('form.contract-selection-label')}
                    testId={'custom-contract-select-end-of-term-calculation'}
                    name="endOfTermCalculation.contractNumber"
                    selectItems={customSelectItems}
                    emptyByDefault
                />
            </Layout.Item>
            <Layout.Item>
                <FormField
                    type="other"
                    errorMessage={
                        reasonHasError && <ErrorMessage testId={'reason-error-message'}>{errors?.reason}</ErrorMessage>
                    }
                    labelText={t('form.end-of-term-calculation-reason-label')}
                >
                    {getEndOfTermCalculationReasonRadioItems(t).map(({ label, value }, index: number) => (
                        <Fieldset.Row key={index} className={'u-mt-xsmall u-mb-small'}>
                            <RadioButton
                                key={index}
                                testId={`radio-end-of-term-calculation-reason.${value}`}
                                value={value}
                                name="endOfTermCalculation.reason"
                                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                                    setFieldValue('endOfTermCalculation.reason', event.target.value)
                                }
                                error={reasonHasError}
                                defaultChecked={value === endOfTermCalculationValues.reason}
                            >
                                {label}
                            </RadioButton>
                        </Fieldset.Row>
                    ))}
                </FormField>
            </Layout.Item>
            {endOfTermCalculationValues.reason === EndOfTermCalculationReason.OTHER && (
                <Layout.Item>
                    <ValidatedTextarea
                        label={t('form.end-of-term-calculation-other-explanation-label')}
                        name={'endOfTermCalculation.otherExplanation'}
                        testId={'other-explanation'}
                        rows={4}
                        enableMaxLength={true}
                        maxLength={500}
                    />
                </Layout.Item>
            )}
            <Layout.Item>
                <ValidatedTextarea
                    label={t('form.comment-label')}
                    name={'endOfTermCalculation.comment'}
                    testId={`test-comment`}
                    rows={4}
                    enableMaxLength={true}
                    maxLength={2000}
                />
            </Layout.Item>
        </>
    );
};
