import React from 'react';
import { Addresses, CustomerType, EditStatus } from '@cp-cz/common';
import { DataOverview, Notification, NotificationStatus } from '@cp-shared-5/frontend-ui';
import { useTranslation } from 'react-i18next';
import { AddressList } from '../common/AddressList';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { EditView } from '../common/edit-view/EditView';
import { changeContactAddressPath } from '../../../navigation/paths';
import { AddressChangeForm } from '../common/edit-view/getSavedData';

export const ContactAddress: React.FC<{
    addresses?: Addresses;
    customerType?: CustomerType;
    handleSubmit: (values: AddressChangeForm) => void;
    backToNonEditMode: () => void;
    lastEditStatus?: EditStatus;
    setLastEditStatus: (editStatus: EditStatus) => void;
}> = ({ addresses, customerType, handleSubmit, backToNonEditMode, lastEditStatus, setLastEditStatus }) => {
    const { t } = useTranslation('my-profile');
    const { path } = useRouteMatch();
    const history = useHistory();

    if (!addresses || !addresses.contactAddress || !customerType) {
        return null;
    }

    const startEditing = (): void => {
        setLastEditStatus(EditStatus.NOT_PERFORMED);
        history.push(changeContactAddressPath());
    };

    const status = lastEditStatus === EditStatus.SUCCESS ? 'success' : 'error';

    return (
        <Switch>
            <Route exact path={changeContactAddressPath()}>
                <EditView
                    onCancel={backToNonEditMode}
                    addresses={addresses}
                    customerType={customerType}
                    handleSubmit={handleSubmit}
                />
            </Route>
            <Route path={path}>
                <DataOverview
                    title={t('addresses-section.contact-address.title')}
                    withoutCardEffect
                    className={'u-pt-none'}
                    buttonLabel={t('addresses-section.contact-address.edit-button')}
                    buttonProps={{ onClick: startEditing }}
                >
                    <AddressList address={addresses.contactAddress} />
                    {lastEditStatus !== EditStatus.NOT_PERFORMED && (
                        <Notification
                            status={NotificationStatus[status]}
                            headline={t(`contact-section.notification.${status}.title`)}
                            text={t(`contact-section.notification.${status}.description`)}
                        />
                    )}
                </DataOverview>
            </Route>
        </Switch>
    );
};
