import { CPDate } from '@cp-shared-5/common-utilities';

export enum CurrencyCode {
    CZK = 'CZK',
    EUR = 'EUR',
}

export enum InstallmentCreditGeneralTermsCode {
    OPUS1401 = 'OPUS1401',
    OPUS1501 = 'OPUS1501',
    OPUS1601 = 'OPUS1601',
    OPUS1604 = 'OPUS1604',
    OPUS1605 = 'OPUS1605',
    OPUS1607 = 'OPUS1607',
    OPUS1610 = 'OPUS1610',
    OPUS1701 = 'OPUS1701',
    OPUS1703 = 'OPUS1703',
    OPUS1707 = 'OPUS1707',
    OPUS1805 = 'OPUS1805',
    OPUS1901 = 'OPUS1901',
    OPUS2002 = 'OPUS2002',
    OPUS2105 = 'OPUS2105',
    OPUS2309 = 'OPUS2309',
    OPUS2401 = 'OPUS2401',
}

export enum OperatingLeaseGeneralTermsCode {
    OPOL1401 = 'OPOL1401',
    OPOL1406 = 'OPOL1406',
    OPOL1601 = 'OPOL1601',
    OPOL1603 = 'OPOL1603',
    OPOL1805 = 'OPOL1805',
    OPOL2309 = 'OPOL2309',
    OPOL1805ENG = 'OPOL1805ENG',
    OPOLVSR1502 = 'OPOLVSR1502',
    OPOLVSR1509 = 'OPOLVSR1509',
    OPOLVSR1510 = 'OPOLVSR1510',
    OPOLVSR1601 = 'OPOLVSR1601',
    OPOLVSR1603 = 'OPOLVSR1603',
    OPOLVSR1610 = 'OPOLVSR1610',
    OPOLVSR1711 = 'OPOLVSR1711',
    OPOLVSR1805 = 'OPOLVSR1805',
    OPOLVSR1805ENG = 'OPOLVSR1805ENG',
    OPOLVSR1904 = 'OPOLVSR1904',
    OPOLVSR2401 = 'OPOLVSR2401',
    OPOLVSS1304 = 'OPOLVSS1304',
    OPOLVSS1401 = 'OPOLVSS1401',
    OPOLVSS1502 = 'OPOLVSS1502',
    OPOLVSS1601 = 'OPOLVSS1601',
    OPOLVSS1610 = 'OPOLVSS1610',
    OPOLVSS1805 = 'OPOLVSS1805',
    OPOLVSS1805ENG = 'OPOLVSS1805ENG',
    OPOLVSS1904 = 'OPOLVSS1904',
}

export enum FinanceLeaseGeneralTermsCode {
    OPFL1501 = 'OPFL1501',
    OPFL1601 = 'OPFL1601',
    OPFL1605 = 'OPFL1605',
    OPFL1701 = 'OPFL1701',
    OPFL1805 = 'OPFL1805',
}

export interface BaseFinancialDetails {
    startDate?: CPDate;
    endDate?: CPDate;
    currentContractAgeMonths?: number;
    variableSymbolCode?: string;
    bankAccountCode?: string;
    bankCode?: string;
    downPayment?: number;
    vehicleReturnDate?: CPDate;
    outstandingBalance?: number;
    nextDueDate?: CPDate;
    nextDueAmount?: number;
    currencyCode?: CurrencyCode;
    residualValue?: number;
    paymentHolidayActiveFlag?: boolean;
    paymentHolidayStartDate?: CPDate;
    paymentHolidayEndDate?: CPDate;
}

export interface InstallmentCreditFinancialDetails extends BaseFinancialDetails {
    generalTermsCode?: InstallmentCreditGeneralTermsCode;
    financeValueOfContract?: number;
    downPayment?: number;
    balloonAmount?: number;
}

export interface OperatingLeaseFinancialDetails extends BaseFinancialDetails {
    generalTermsCode?: OperatingLeaseGeneralTermsCode;
}

export interface FinanceLeaseFinancialDetails extends BaseFinancialDetails {
    generalTermsCode?: FinanceLeaseGeneralTermsCode;
}

export type FinancialDetails =
    | InstallmentCreditFinancialDetails
    | OperatingLeaseFinancialDetails
    | FinanceLeaseFinancialDetails;
